<template>
<div class="inner-banner inner-bg9">
<div class="container">
<div class="inner-title">
<h3>404 Error Page</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>Pages</li>
<li>404 Error Page</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>

<div class="error-area ptb-100">
	<div class="d-table">
		<div class="d-table-cell">
			<div class="error-content">
				<h1>4 <span>0</span> 4</h1>
				<h3>Oops! Page Not Found</h3>
				<p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
				<router-link to="/" class="default-btn">Return To Home Page</router-link>
			</div>
		</div>
	</div>
</div>
</template>
<script>
export default {
    name: 'NotFound',
    mounted() {
        document.title = '404 Not Found'
    },
}
</script>