<template>
<div class="inner-banner inner-bg13">
<div class="container">
<div class="inner-title">
<h3>Mint NFT</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>Pages</li>
<li>Mint NFT</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>


<div class="collection-widget-area pt-100 pb-70">
<div class="container">
<div class="row">
<div class="col-lg-3">
<div class="author-profile-sidebar mr-20">
<div class="author-user">
<template v-if="$store.state.user.photo">
<img :src="$store.state.user.base64_photo" alt="Images">
</template>
<template v-else>
<img src="../assets/dab/images/collections/collection-profile2.jpg" alt="Images">
</template>
<i class="ri-check-line"></i>
</div>
<h3><a href="/author-profile/">{{$store.state.user.full_name}}</a></h3>
<span>@{{$store.state.user.username}}</span>
 <p>Earn income by posting your works</p>
<div class="sp-title">0x76669f...a0e9ca52 <i class="ri-folders-line"></i></div>
<div class="author-content">
<div class="content-left">
<span>Followers</span>
<h4>2941</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="col-lg-9">
<div class="collection-form-area">
<div class="section-title">
<h2>Mint NFT</h2>
</div>
<div class="collection-form">
<form @submit.prevent="submitCreateItemForm">
<div class="row">
<div class="col-lg-12">
<div class="form-group">
<label>Address</label>
<input v-if="tokenUri" type="text" name="address" id="address" class="form-control" ref="address" :value="tokenUri.address" placeholder="e. g. '0x76669f...a0e9ca52'">
<input v-else type="text" name="address" id="address" class="form-control" ref="address" :value="metamaskWallet['metaMaskAddress']" placeholder="e. g. '0x76669f...a0e9ca52'">
</div>
</div>
<div class="col-lg-12">
<div class="form-group">
<label>Token Name</label>
<input v-if="tokenUri" type="text" name="name" id="name" class="form-control" ref="name" :value="tokenUri.name" placeholder="e. g. 'walking in the air'">
<input v-else type="text" name="name" id="name" class="form-control" ref="name" :value="item.title" placeholder="e. g. 'walking in the air'">
</div>
</div>
<div class="col-lg-12 col-md-12">
<div class="form-group">
<label>Description</label>
<textarea v-if="tokenUri" name="description" class="form-control" id="description" ref="description" :value="tokenUri.description" cols="30" rows="5" placeholder="e. g. 'after purchasing you will able to get the real product'"></textarea>
<textarea v-else name="description" class="form-control" id="description" ref="description" :value="item.description" cols="30" rows="5" placeholder="e. g. 'after purchasing you will able to get the real product'"></textarea>
</div>
</div>
<div class="col-lg-12">
<div class="form-group">
<label>Asset URL</label>
<input v-if="tokenUri" type="text" class="form-control" placeholder="e. g. '1'" ref="assetURL" :value="tokenUri.assetURL">
<input v-else type="text" class="form-control" placeholder="e. g. 'https://bitcrome.com/items/4d00c9cb75ed44178a912caa7aa13a8d/'" ref="assetURL" :value="itemUrl">
</div>
</div>
<div class="col-lg-12 col-md-12">
<button class="default-btn border-radius-5" tag="button" :disabled="tokenUri" @click="mint">Mint</button>
</div>
</div>

</form>
</div>
</div>
</div>
</div>
</div>
</div>
<toast :show="toast.show" :text="toast.text" @hide-toast="hideToast"></toast>
 <vue-metamask userMessage="msg" @onComplete="onComplete"></vue-metamask>
</template>
<script>
import config from '../config.js'
import VueMetamask from 'vue-metamask';
import UploadFiles from "@/components/UploadFiles";
import axios from 'axios'
import Toast from '@/components/Toast.vue'
export default {
    name: 'MintNFT',
    components: {
	    UploadFiles,
	    VueMetamask,
	    Toast
	},
	data() {
	    return {
	      item: {},
	      tokenUri:{},
	      metamaskWallet:{},
	      itemUrl:"",
	    }
	  },
    mounted() {
        document.title = 'Mint NFT';
        this.item = JSON.parse(localStorage.getItem("item"));
        this.tokenUri=JSON.parse(localStorage.getItem("tokenUri"));
        this.metamaskWallet = localStorage.getItem("metamaskWallet");
        this.itemUrl = "https://bitcrome.com/items/"+this.item.uuid+"/";
    },
    computed: {
  		toast() {
  			return this.$store.getters.toast;
  		}
 	},
    methods: {
    	onComplete(data){
	    	console.log('data:', data);
	    	console.log(data['metaMaskAddress']);
	    	if(!data['metaMaskAddress']) {
	    		alert("Please download Metamask to mint NFT. Link: https://metamask.io/download.html");
	    	}
	    	this.metamaskWallet = data
	    	this.$store.commit('setMetamaskWallet', data);
	    },
    	hideToast() {
  			this.$store.commit("hideToast");
  		},
  		
  		async mintNFT(tokenURIDataUrl) {
			//const API_URL = "https://eth-ropsten.alchemyapi.io/v2/L6DL1hRnnw_P9FeVojb-qaOxfJUbmiLU"
			const API_URL=config.apiUrl
			const PUBLIC_KEY = "0x28F90bA1353b21Ca3fac3Ab665b1DBDF8E3FB539"
			const PRIVATE_KEY = "f9ef743b5b510b8cddf6fdbfcdc57c07457ddf028b67e00c6a4e0f90ce444e79"
			
			const { createAlchemyWeb3 } = require("@alch/alchemy-web3")
			const web3 = createAlchemyWeb3(API_URL)
			
			//const contract = require("../../artifacts/contracts/MyNFT.sol/MyNFT.json")
			const contract = require("../../artifacts/contracts/BITCROME.sol/BITCROME.json")
			console.log(JSON.stringify(contract.abi))
			const contractAddress = config.contractAddress
			const nftContract = new web3.eth.Contract(contract.abi, contractAddress)
			const nonce = await web3.eth.getTransactionCount(PUBLIC_KEY, "latest") //get latest nonce
			//the transaction
			const tx = {
			    from: PUBLIC_KEY,
			    to: contractAddress,
			    nonce: nonce,
			    gas: 500000,
			    data: nftContract.methods.mintNFT(PUBLIC_KEY, tokenURIDataUrl).encodeABI(),
			}
			
			const signPromise = web3.eth.accounts.signTransaction(tx, PRIVATE_KEY)
			signPromise
			    .then((signedTx) => {
			      web3.eth.sendSignedTransaction(
			        signedTx.rawTransaction,
			        function (err, hash) {
			          if (!err) {
			            this.message = "The hash of your transaction is: "+hash;
			            const tokenUri=JSON.parse(localStorage.getItem("tokenUri")); 
			            console.log(tokenUri.id)
			            console.log(hash)
			            const formData = {
			            	txn_hash:hash,
			            	id:tokenUri.id
		            	}
			            axios.put("/api/tokenuris/"+tokenUri.id+"/", formData)
			                .then(response => {
			                	console.log(response.data);
			                    const toPath = this.$route.query.to || '/create-collection/';
			                    this.$router.push(toPath);
			                    this.message = "Update the transaction hash successfully!";
			                    
			                })
			                .catch(error => {
			                    if (error.response) {
			                        for (const property in error.response.data) {
			                        	this.message = "Error the transaction hash error!";
			                        }
			                    } else {
			                        this.message = "Something went wrong. Please try again";
			                        console.log(JSON.stringify(error))
			                    }
			                })
			            
			            console.log(
			              "The hash of your transaction is: ",
			              hash,
			              "\nCheck Alchemy's Mempool to view the status of your transaction!"
			            )
			          } else {
			            console.log(
			              "Something went wrong when submitting your transaction:",
			              err
			            )
			          }
			        }
			      )
			    })
			    .catch((err) => {
			      console.log(err)
			    })
		},
		
    	async mint(){
    		const token = localStorage.getItem("token")
            const item = JSON.parse(localStorage.getItem("item"))
            axios.defaults.headers.common["Authorization"] = "Token " + token
    		//create token uri 
    		const tokenUriFormData = {
    			name: this.$refs.name.value,
    			address:this.$refs.address.value,
    			description: this.$refs.description.value,
    			assetURL: this.$refs.assetURL.value,
    			traits:[{}],
    			item_uuid: item.uuid,
    			sale_type: localStorage.getItem("sale_type")
	       	}
			console.log(tokenUriFormData);	
			
	        await axios
	                .post("/api/tokenuris/", tokenUriFormData)
	                .then(response => {
	                	console.log(response.data);
	                	this.message = "Create token uri successfully!";
	                	this.$store.commit('showToast', "Create token uri successfully!");
	                    const toPath = this.$route.query.to || '/';
	                    //this.$router.push(toPath);
	                    this.tokenUri=JSON.stringify(response.data)
	                    localStorage.setItem("tokenUri", JSON.stringify(response.data));
	                    this.mintNFT("https://bitcrome.com/tokenuris/"+this.tokenUri.id+"/");
	                    
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        console.log(JSON.stringify(error))
	                    }
	        })
	                
    		this.$store.commit('showToast', "Mint NFT...");
    	},
    	reset() {
    		localStorage.removeItem("item");
    		localStorage.removeItem("tokenUri");
    		const toPath = this.$route.query.to || '/create-collection/';
	        this.$router.push(toPath);
    	},
    }
}
</script>