<template>
<div class="inner-banner inner-bg7">
	<div class="container">
		<div class="inner-title">
			<h3>{{ page.title }}</h3>
			<ul>
				<li>
				<router-link to="/">Home</router-link>
				</li>
				<li>Pages</li>
				<li>{{ page.title }}</li>
			</ul>
			<div class="inner-shape">
				<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
				<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
			</div>
		</div>
	</div>
</div>

<div class="pt-100 pb-70">
	<div class="container">
		<div class="section-title text-center">
			<span class="sp-title">{{ page.title }}</span>
			<h2 class="m-auto">{{ page.description }}</h2>
		</div>
		<div class="row pt-45">
			<div class="col-lg-12">
				<div class="single-content">
					<div v-html="page.content"/>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Page',
    components: {
    },
    data() {
        return {
            page: {}
        }
    },
    mounted() {
        this.getPage()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Page') {
                this.getPage()
            }
        }
    },
    methods: {
        async getPage() {
            const pageSlug = this.$route.params.page_slug

            this.$store.commit('setIsLoading', true)

            axios
                .get(`/api/v1/pages/${pageSlug}/`)
                .then(response => {
                    this.page = response.data
                    document.title = this.page.title
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>