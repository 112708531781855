import { createStore } from 'vuex'

export default createStore({
  state: {
    cart: {
        items: [],
    },
	isWalletConnected:false,
    isAuthenticated: false,
    token: '',
    accessToken:'',
	refreshToken:'',
	user: {},
    isLoading: false,
	isRightNetwork: true,
    miscData:{},
    mainMenus:[],
    footerMenuitems:[],
    homeSliders: [],
    storages: {},
	topSellers:[],
	metamaskWallet:{},
	tokenUri:{},
	trendingArtworks:[],
	liveAuctions:[],
	featuredAssets:[],
	topAuthors:[],
	hotCollections:[],
	toast: {
    	text: "",
    	show: false
    },
	rate:{},
  },
	getters: {
        toast: (state) => {
            return state.toast;
        }
    },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart'))
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart))
      }
      if (localStorage.getItem('token')) {
          state.token = localStorage.getItem('token')
          state.isAuthenticated = true
		  localStorage.setItem('isAuthenticated',true)
		  state.isWalletConnected = true
		  localStorage.setItem('isWalletConnected',true)
      } else {
          state.token = ''
          state.isAuthenticated = false
          localStorage.setItem('isAuthenticated',false)
		  state.isWalletConnected = false
		  localStorage.setItem('isWalletConnected',false)
      } 
	  if (localStorage.getItem('user')) {
        state.user = JSON.parse(localStorage.getItem('user'))
      } else {
        localStorage.setItem('user', JSON.stringify(state.user))
      }
	  if (localStorage.getItem('rate')) {
        state.rate = JSON.parse(localStorage.getItem('rate'))
      } else {
        localStorage.setItem('rate', JSON.stringify(state.rate))
      }
	  if (localStorage.getItem('accessToken')) {
        state.accessToken = JSON.parse(localStorage.getItem('accessToken'))
      } else {
        localStorage.setItem('accessToken', JSON.stringify(state.accessToken))
      }
	  if (localStorage.getItem('refreshToken')) {
        state.refreshToken = JSON.parse(localStorage.getItem('refreshToken'))
      } else {
        localStorage.setItem('refreshToken', JSON.stringify(state.refreshToken))
      }
		
    },
    addToCart(state, item) {
      const exists = state.cart.items.filter(i => i.product.id === item.product.id)
      if (exists.length) {
        exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
      } else {
        state.cart.items.push(item)
      }

      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setMiscData(state, miscData) {
      state.miscData = miscData
    },
    setToken(state, token) {
        state.token = token
        state.isAuthenticated = true
    },  
	setIsWalletConnected(state, isWalletConnected) {
        state.isWalletConnected = isWalletConnected
		localStorage.setItem("isWalletConnected", JSON.stringify(isWalletConnected))
    },  
    setIsAuthenticated(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated
		localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated))
    },  
    setMetamaskWallet(state,data) {
		state.metamaskWallet=data
		localStorage.setItem("metamaskWallet", JSON.stringify(data))
	},
	setTokenUri(state,data) {
		state.tokenUri=data
		localStorage.setItem("tokenUri", JSON.stringify(data))
	},
	setRate(state,data) {
		state.rate=data
		localStorage.setItem('rate', JSON.stringify(state.rate))
	},
	setAccessToken(state,data) {
		state.accessToken=data
		localStorage.setItem('accessToken', JSON.stringify(state.accessToken))
	},
	setRefreshToken(state,data) {
		state.refreshToken=data
		localStorage.setItem('refreshToken', JSON.stringify(state.refreshToken))
	},
	setUser(state,data) {
		state.user=data
		localStorage.setItem('user', JSON.stringify(state.user))
	},
	setTrendingArtworks(state,data) {
		state.trendingArtworks=data
		localStorage.setItem('trendingArtworks', JSON.stringify(data))
	},
	setLiveAuctions(state,data) {
		state.liveAuctions=data
		localStorage.setItem('liveAuctions', JSON.stringify(data))
	},
	setFeaturedAssets(state,data) {
		state.featuredAssets=data
		localStorage.setItem('featuredAssets', JSON.stringify(data))
	},
	setTopAuthors(state,data) {
		state.topAuthors=data
		localStorage.setItem('topAuthors', JSON.stringify(data))
	},
	setTopSellers(state,data) {
		state.topSellers=data
		localStorage.setItem('topSellers', JSON.stringify(data))
	},
	setHotCollections(state,data) {
		state.hotCollections=data
		localStorage.setItem('hotCollections', JSON.stringify(data))
	},
    removeToken(state) {
        state.token = ''
        state.isAuthenticated = false
    },
    clearCart(state) {
      state.cart = { items: [] }
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
	showToast: (state, toastText) => {
            state.toast.show = true;
            state.toast.text = toastText;
        },
    hideToast: (state) => {
            state.toast.show = false;
            state.toast.text = "";
        }
  },
  actions: {
  },
  modules: {
  }
})
