<template>
	<div class="inner-banner inner-bg4">
		<div class="container">
			<div class="inner-title">
				<h3>Log In</h3>
			<ul>
				<li>
					<a href="/">Home</a>
				</li>
				<li>Pages</li>
				<li>Log In</li>
			</ul>
			<div class="inner-shape">
				<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
				<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
			</div>
			</div>
		</div>
	</div>
	<div class="user-area pt-100 pb-70">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-12">
					<div class="user-all-form">
					<div id="wallet-info">
						<div class="row">
						<div class="col-lg-12 col-md-12 text-center">
						<h2>Connect your wallet.</h2>
						<p>Connect with one of our available wallet providers or create a new one.</p>
						<p>Download <a href="https://metamask.io/download/">here</a> if Metamask does not exist.</p>
						<p>Check <a href="https://chainlist.org/">here</a> to add Polygon Matic or others.</p>
						</div>
						</div>
					</div>
					<div class="contact-form">
                    <div class="row">
						<div class="col-lg-12 col-md-12 text-center">
							<button type="submit" class="default-btn" v-on:click="submitForm">
								Login With Metamask
							</button>
						</div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'LogIn',
    data() {
        return {
            email: '',
            password: '',
            errors: [],
            metamaskWallet:{},
      		metamask_address: "",
      		metamask_type:"",
      		isWalletConnected:false,
      		isAuthenticated:false,
        }
    },
    mounted() {
        document.title = 'Log In | Digital Asset Bank';
        this.isWalletConnected = JSON.parse(localStorage.getItem("isWalletConnected"));
  		this.isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));
  		this.metamaskWallet = JSON.parse(localStorage.getItem("metamaskWallet"));
  		if(this.metamaskWallet){
    		this.metamask_address = this.metamaskWallet['metaMaskAddress'];
    	}
    
  		console.log(this.isWalletConnected);
  		console.log(this.isAuthenticated);
  		console.log(this.metamaskWallet);
    },
    methods: {
    	async signup() {
    		axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            
    		const formData = {
            	user: {
			        full_name: accounts[0],
			    },
                public_address: accounts[0]
            }

            await axios
                .post("/api/metamask/", formData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                    const token = response.data.access
                    this.$store.commit('setToken', token)
                    this.$store.commit('showToast', 'Login successfully!');
                    
                    //axios.defaults.headers.common["Authorization"] = "Token " + token
                    axios.defaults.headers.common['Authorization'] = "Bearer " + token
					this.$store.state.user = response.data.user
					
                    localStorage.setItem("token", token)
                    this.$store.commit('setIsWalletConnected',true);
					this.$store.state.isWalletConnected=true;
					this.$store.commit('setUser',response.data.user)
					//localStorage.setItem("user", response.data.user)
                    const toPath = this.$route.query.to || '/'
                    this.$router.push(toPath)
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${error.response.data[property]}`)
                            this.$store.commit('showToast', `${error.response.data[property]}`);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    	},
    	
    	async login(nonce) {
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            //let msg = web3.utils.keccak256('Hello from Bitcrome!');
            let public_address =  accounts[0];
            let msg = 'I am signing my one-time nonce '+nonce
            let sign = await web3.eth.personal.sign(msg, public_address);
            
            //const sign=web3.personal.sign(web3.utils.toUtf8("Hello from Toptal!"), 0x28F90bA1353b21Ca3fac3Ab665b1DBDF8E3FB539, console.log);
            
            console.log(sign)
            const formData = {
            	signature:sign,
            }
			
			
            await axios
                .post("/api/metamask/login/"+public_address, formData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                    
                    const token = response.data.access
                    console.log(token)
                    this.$store.commit('setToken', token)
                    this.$store.commit('showToast', 'Login successfully!');
                    
                    //axios.defaults.headers.common["Authorization"] = "Token " + token
                    axios.defaults.headers.common['Authorization'] = "Bearer " + token
					this.$store.state.user = response.data.user
					
                    localStorage.setItem("token", token)
                    
                    this.$store.commit('setIsWalletConnected',true);
					this.$store.state.isWalletConnected=true;
                    this.isWalletConnected = true;
                    
					this.$store.commit('setUser',response.data.user)
					//localStorage.setItem("user", response.data.user)
                    const toPath = this.$route.query.to || '/'
                    this.$router.push(toPath)
                })
                .catch(error => {
                	this.signup()
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${error.response.data[property]}`)
                            this.$store.commit('showToast', `${error.response.data[property]}`);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
        },
        async submitForm() {
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            //let msg = web3.utils.keccak256('Hello from Bitcrome!');
            let public_address =  accounts[0];
            await axios.get("/api/metamask/"+public_address)
            .then(response => {
            	console.log(response.data.nonce) 	
            	this.login(response.data.nonce)
            	
            }).catch(error => {
            	this.signup()
            	console.log(error)
            })
       }
    }
}
</script>