<template>
<div class="inner-banner inner-bg10">
<div class="container">
<div class="inner-title">
<h3>Author Profile</h3>
<ul>
	<li><router-link to="/">Home</router-link></li>
	<li>Pages</li>
	<li>Author Profile</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>

<div class="author-profile-area pt-100 pb-70">
<div class="container">
<div class="row">
<div class="col-lg-3">
<div class="author-profile-sidebar  mr-20">
<div class="author-user">
<template v-if="$store.state.user.photo">
<img :src="$store.state.user.base64_photo" :alt="$store.state.user.full_name">
</template>
<template v-else>
<img src="../assets/dab/images/author/author-profile.jpg" :alt="$store.state.user.full_name">
</template>
<i class="ri-check-line"></i>
</div>
<h3><a href="#">{{truncate($store.state.user.full_name,20, '...')}}</a> <a href="/author-profile/edit/"><i class="ri-pencil-fill"></i></a></h3>
<span>@{{truncate($store.state.user.username,20, '...')}}</span>
<p>All works are here</p>
<div class="sp-title" v-if="metamask_address">{{truncate(metamask_address,20, '...')}} <i class="ri-folders-line"></i></div>
<div class="author-content" v-if="user.extra_data">
<div class="content-left">
<span>Socials</span>
<h4></h4>
</div>

<div class="content-right">
Follow
<ul class="author-social">
<li v-for="social in user.extra_data.socials">
<a :href="social.url" target="_blank">
<i :class="'ri-'+social.name+'-fill'"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="col-lg-9">
<div class="tab featured-tab-area featured-tab-area-ml">
<div class="row align-items-center">
<div class="col-lg-4 col-md-4">
<div class="section-title">
<h2>Items</h2>
</div>
</div>
<div class="col-lg-8 col-md-8" v-if="count > 0">
<ul class="tabs">
<li>
<a href="#">
All
</a>
</li>
</ul>
</div>
<div class="col-lg-12" v-else>
<p>No items found. Please <a href="/create-collection/">create collection</a>.</p>
</div>
</div>
<div class="tab_content current active pt-45">
<div class="tabs_item current">
<div class="row justify-content-center">
<div class="col-lg-4 col-md-6" v-for="(item, index) in itemList" :key="index"> 
<div class="featured-card box-shadow" >
<div class="featured-card-img">
<a :href="'/items/'+item.uuid+'/'">
  <img :src="api_baseurl+item.featured_image" :alt="item.title" v-if="item.featured_image">
  <img src="../assets/dab/images/featured/featured-img1.jpg" :alt="item.title" v-else>
</a>
<p v-if="false"><i class="ri-heart-line"></i></p>

<button type="button" class="default-btn border-radius-5" v-on:click="allowBuy(item.transaction.tokenId,item.price)"  v-if="item.transaction.allow_buy_price==0">Allow Buy</button>
<button type="button" class="default-btn border-radius-5" v-on:click="goto('/items/'+item.uuid+'/')" v-else>View Detail</button>
</div>
<div class="content">
<h3><a :href="'/items/'+item.uuid+'/'">{{item.title}}</a></h3>
<div class="content-in">
<div class="featured-card-left">
<span v-if="item.price>0">{{convertCrypto(item.price,item.currency_code,'ETH')}} ETH</span>
<h4 v-if="item.price==0">Bid 80 ETH </h4>
</div>
<a :href="'/items/'+item.uuid+'/'" class="featured-content-btn">
<i class="ri-arrow-right-line"></i>
</a>
</div>

<a :href="'/author-profile/'+item.user_uuid" class="featured-user-option">
<img :src="api_baseurl+item.user_photo" :alt="item.user_full_name" v-if="item.featured_image">
<img src="../assets/dab/images/featured/featured-user1.jpg" :alt="item.user_full_name" v-else>
<span>Created by @{{truncate(item.user_full_name,20,'...')}}</span>
</a>
</div>
</div>
</div>

</div>
</div>
</div>
</div>
<div class="col-lg-12 col-md-12">
<div class="pagination-area" v-if="num_pages > 1">
<a v-if="showPrev" v-on:click="loadPrev()" class="prev page-numbers">
<i class="ri-arrow-left-s-line"></i>
</a>
<span class="page-numbers current" aria-current="page">{{currentPage}}</span>
<a v-if="showNext" v-on:click="loadNext()" class="next page-numbers">
<i class="ri-arrow-right-s-line"></i>
</a>
</div>
</div>
</div>

</div>
</div>
</div>


<div class="involved-area-two pt-100 pb-70">
<div class="container">
<div class="section-title text-center">
<h2>Get Involved</h2>
</div>
<div class="row pt-45">
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-flight-takeoff-line"></i>
</div>
<h3>Join Our <b>Community</b></h3>
<ul class="social-link">
<li>
<a href="https://www.google.com/" target="_blank">
<i class="ri-google-fill"></i>
</a>
</li>
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
<li>
<a href="https://www.linkedin.com/" target="_blank">
<i class="ri-linkedin-fill"></i>
</a>
</li>
</ul>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-checkbox-circle-line"></i>
</div>
<h3>Become A <b>Creator</b></h3>
<a href="/register/" class="default-btn">Register</a>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-heart-2-line"></i>
</div>
<h3>Become A <b>Charity Partner</b></h3>
<a href="/create-collection/" class="default-btn">Express Interest</a>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-discuss-line"></i>
</div>
<h3>Reach Out To <b>Our Team</b></h3>
<a href="/contact-us/" class="default-btn">Contact Now</a>
</div>
</div>
</div>
</div>
</div>
 	
</template>
<script>
import axios from 'axios'
import Toast from '@/components/Toast.vue'
import config from '../config.js'

export default {
    name: 'MyProfile',
    components: {
  	},
    data() {
	    return {
	      itemList: [],
	      count:0,
	      currentPage:1,
	      page_size:6,
	      showNext:false,
	      showPrev:false,
	      showPagination:false,
	      num_pages:0,
	      user:{},
	      metamaskWallet:{},
	      metamask_address:"",
	      api_baseurl:"",
	      rate_amount:1,
	    }
	  },
	beforeCreate() {
    	this.$store.commit('initializeStore')
    	const token = this.$store.state.token
	    if (token) {
	        //axios.defaults.headers.common['Authorization'] = "Token " + token
	        axios.defaults.headers.common['Authorization'] = "Bearer " + token
	    } else {
	        axios.defaults.headers.common['Authorization'] = ""
	    }
    },
   mounted() {
   		document.title = 'My Profile';
    	this.api_baseurl= axios.defaults.baseURL
    	this.getItemList();
        this.rate_amount=this.$store.state.rate.amount
        
		this.metamaskWallet = JSON.parse(localStorage.getItem("metamaskWallet"));
	  	console.log(this.metamaskWallet);
	    if(this.metamaskWallet){
	    	this.metamask_address = this.metamaskWallet['metaMaskAddress'];
	    }
	    console.log(this.metamask_addresss);
    },
    methods: {
    	goto(url){
			window.location.href = url
		},
    	
    	async allowBuy(tokenId,price) {
    		this.$store.commit('setIsLoading', true)
    		console.log(tokenId)
    		console.log(price)
    		console.log(this.metamask_address)
    		
        	//const API_URL="https://eth-ropsten.alchemyapi.io/v2/mkmpYPZHBR0zola6xOzx4A8E5XCcrbcI"
        	const API_URL=config.apiUrl
        	const MYWALLET_ADDRESS = this.metamask_address
        	//const MYWALLET_ADDRESS = this.metamaskWallet['metaMaskAddress']
        	const { createAlchemyWeb3 } = require("@alch/alchemy-web3")
			const web3 = createAlchemyWeb3(API_URL)
			const ETH_PRICE = (1000000000000000000*price).toFixed(0).toString();
			console.log(ETH_PRICE);
			
			const contract = require("../../artifacts/contracts/BITCROME.sol/BITCROME.json")
			const contractAddress = config.contractAddress
			const nftContract = new web3.eth.Contract(contract.abi, contractAddress)
			const nonce = await web3.eth.getTransactionCount(MYWALLET_ADDRESS, "latest") //get latest nonce
			nftContract.methods.allowBuy(tokenId,ETH_PRICE).send({from:MYWALLET_ADDRESS});
			
			
			
			this.$store.commit('setIsLoading', false)
        },
    	loadPrev: function () {
    		this.currentPage -=1
    		this.getItemList()
    	},
    	loadNext: function () {
    		this.currentPage +=1
    		this.getItemList()
    	},
    	async getItemList() {
    		this.$store.commit('setIsLoading', true)
    		const token = localStorage.getItem("token")
            const item = JSON.parse(localStorage.getItem("item"))
            const user = JSON.parse(localStorage.getItem("user"))
            axios.defaults.headers.common["Authorization"] = "Token " + token
			if (!this.currentPage) {
				this.currentPage = 1
			} 
			
			//console.log(user.id)
			
	        await axios.get("/api/items/?user_id="+user.id+"&page="+this.currentPage+"&page_size=6")
	                .then(response => {
	                	if (response.data.count == 0) {
	                		this.showPagination = false 
	                	} else {
	                		this.showPagination = true
	                	}
	                	console.log(response.data);
	                	console.log(response.data.results);
	                	this.count = response.data.count;
	                	this.num_pages = this.count/this.page_size;
	                	this.$store.commit('showToast', "Get item list");
	                    const toPath = this.$route.query.to || '/';
	                    //this.$router.push(toPath);
	                    this.itemList=response.data.results;
	                    if(response.data.next) {
	                    	this.showNext=true
	                    } else {
	                    	this.showNext=false
	                    }
	                    if(response.data.previous) {
	                    	this.showPrev=true
	                    } else {
	                    	this.showPrev=false
	                    }
	                    localStorage.setItem("itemList", response.data.results);
	                    this.$store.commit('setIsLoading', false)     
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.$store.commit('showToast', "Something went wrong. Please try again");
	                        console.log(JSON.stringify(error))
	                    }
	        })
	           
    		this.$store.commit('showToast', "Get Item List...");
    		
    	},
    }
}
</script>