<template>
<div class="inner-banner inner-bg6">
<div class="container">
<div class="inner-title">
<h3>Token URI</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>NFT</li>
<li>Token URI</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>

<div class="blog-details-area pt-100 pb-70" v-if="format !== 'html'">
<div class="container">
<div class="row">
<div class="col-lg-12">
<div class="blog-details-content" v-if="tokenuri">
<div class="content">
<h1>{{tokenuri.name}}</h1>
</div>
<div class="blog-article">
<p>Description: {{tokenuri.description}}</p>
<p>ID: {{tokenuri.id}}</p>
<p>Address: {{tokenuri.address}}</p>
<p>Asset URL: {{tokenuri.assetURL}}</p>
<p v-if="tokenuri.txn_hash">Transaction Hash: {{tokenuri.txn_hash}}</p>
<p>Token ID: {{tokenuri.tokenId}}</p>
<p>
Traits:
<ul v-if="tokenuri.traits">
<li v-for="val,key in JSON.parse(tokenuri.traits)">{{key}}:{{val}}</li>
</ul>
</p>
</div>
<div class="article-share-area">
<div class="row align-items-center">
<div class="col-lg-5 col-sm-5">
</div>
<div class="col-lg-7 col-sm-7">
<div class="article-social-icon">
<ul class="social-icon">
<li class="title">Share Post</li>
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
<li>
<a href="https://www.linkedin.com/" target="_blank">
<i class="ri-linkedin-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>

</div>
</div>
</div>
</div>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'TokenUri',
    components: {
    },
    data() {
        return {
            tokenuri: {},
            api_baseurl: "",
            format:"html",
        }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        this.getTokenUri()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'TokenUri') {
                this.getTokenUri()
            }
        }
    },
    methods: {
        async getTokenUri() {
            const tokenuriSlug = this.$route.params.tokenuri_slug
            this.$store.commit('setIsLoading', true)
			const format = this.$route.query.format
			this.format=format
            axios
                .get(`/api/tokenuris/${tokenuriSlug}/`)
                .then(response => {
                    this.tokenuri = response.data
                    document.title = this.tokenuri.name 
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>