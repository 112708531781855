<template>
<div class="banner-area">
<div class="container-fluid">
<div class="row align-items-center">
<div class="col-lg-6">
<div class="banner-content">
<span>Buying & Selling NFT World</span>
<h1>Discover, Collect, and Sell Extraordinary NFTs</h1>
<p>
</p>
<div class="banner-btn">
<a href="/pages/about-us/" class="default-btn border-radius-5">Explore More</a>
<a href="/discover-assets/" class="default-btn two border-radius-5">Discover Assets</a>
</div>
</div>
</div>
<div class="col-lg-6">
<div class="banner-card-area">
<div class="row">

<div class="col-lg-6 col-sm-6" v-for="item in trendingArtworks.slice(0,2)">
<div class="banner-card">
 <div class="banner-card-img">
<img :src="api_baseurl+item.featured_image" :alt="item.title" v-if="item.featured_image">
<img src="../assets/dab/images/home-one/home-one-img1.jpg" :alt="item.title" v-else>
<div class="banner-card-content">
<div class="card-left">
<span>Start Bid</span>
<h3>{{convertCrypto(item.price,item.currency_code,'ETH')}} ETH</h3>
</div>
<div class="card-right" v-if="item.price==0">
<h3>Remaining Time</h3>
<div class="timer-text" data-countdown="2021/10/10"></div>
</div>
</div>
</div>
<div class="content">
<div class="banner-user-list">
<div class="banner-user-list-img">
<a :href="'/author-profile/'+item.user_uuid">
<img :src="api_baseurl+item.user_photo" :alt="item.user_full_name" v-if="item.user_photo" style="width:50px !important;height:50px !important;" >
<img src="../assets/dab/images/home-one/home-one-user1.jpg" :alt="item.user_full_name" v-else>
</a>
<i class="ri-check-line"></i>
</div>
<h3><a :href="'/author-profile/'+item.user_uuid">{{item.title}}</a></h3>
<span>Created by <a :href="'/author-profile/'+item.user_uuid">@{{item.user_full_name}}</a></span>
</div>
<a :href="'/author-profile/'+item.user_uuid" class="banner-user-btn"><i class="ri-arrow-right-line"></i></a>
<button type="button" class="default-btn border-radius-5" v-if="item.price==0">Place Bid</button>
<button type="button" class="default-btn border-radius-5" v-else>Buy Now</button>
</div>
</div>
</div>

</div>
</div>
</div>
</div>
</div>
<div class="banner-shape">
<div class="shape-circle1">
<img src="../assets/dab/images/home-one/circle1.png" alt="Images">
</div>
<div class="shape-circle2">
<img src="../assets/dab/images/home-one/circle2.png" alt="Images">
</div>
<div class="shape-bg">
<img src="../assets/dab/images/home-one/bg-shape.png" alt="Images">
</div>
</div>
</div>

<div class="trending-area pt-100 pb-70" v-if="trendingArtworks.length">
<div class="container">
<div class="row">
<div class="col-lg-8 col-md-7">
<div class="section-title">
<h2>Trending Artwork</h2>
</div>
</div>
<div class="col-lg-4 col-md-5">
<div class="trending-btn text-end">
<a href="/pages/about-us/" class="default-btn border-radius-5">Explore More</a>
</div>
</div>
</div>
<div class="trending-slider owl-carousel owl-theme pt-45">
<div class="trending-item" v-for="item in trendingArtworks">
<div class="trending-img">
<a :href="'/items/'+item.uuid+'/'">
<img :src="api_baseurl+item.featured_image" :alt="item.title" v-if="item.featured_image">
<img src="../assets/dab/images/trending/trending-img1.jpg" :alt="item.title" v-else>
</a>
<div class="trending-user">
<a :href="'/author-profile/'+item.user_uuid" class="trending-user-option">
<template v-if="item.user_photo">
<img :src="api_baseurl+item.user_photo" :alt="item.user_full_name">
</template>
<template v-else>
<img src="../assets/dab/images/trending/trending-user1.jpg" :alt="item.user_full_name">
</template>
<span>Created by @{{item.user_full_name}}</span>
</a>
</div>
<button type="button" class="default-btn border-radius-5" v-on:click="goto('/items/'+item.uuid+'/')" v-if="item.price==0">Place Bid</button>
<button type="button" class="default-btn border-radius-5" v-on:click="goto('/items/'+item.uuid+'/')" v-else>Buy Now</button>
<div class="trending-title">
<span>{{convertCrypto(item.price,item.currency_code,'ETH')}} ETH</span>
<h3></h3>
</div>
</div>
<div class="content">
<h3>
<a :href="'/items/'+item.uuid+'/'">
{{item.title}}
</a>
</h3>
</div>
</div>

</div>
</div>
</div>

<div class="top-sellers-area pb-70" v-if="topSellers.length">
<div class="container">
<div class="row">
<div class="col-lg-8 col-md-7">
<div class="section-title">
<h2>Top Sellers</h2>
</div>
</div>
<div class="col-lg-4 col-md-5">
<div class="trending-btn text-end">
<a href="/pages/about-us/" class="default-btn border-radius-5">Explore More</a>
</div>
</div>
</div>
<div class="row justify-content-center pt-45">
<div class="col-lg-3 col-6 col-md-4" v-for="(user,index) in topSellers">
<div class="top-sellers-item">
<div class="number">{{index+1}}.</div>
<div class="top-sellers-img">
<a :href="'/author-profile/'+user.uuid+'/'">
<img :src="user.photo_thumbnail" :alt="user.full_name" style="width:65px !important;height:65px !important;" v-if="user.photo">
<img src="../assets/dab/images/top-sellers/top-sellers1.jpg" :alt="user.full_name" style="width:65px !important;height:65px !important;" v-else>
</a>
<i class="ri-check-line"></i>
</div>
<div class="content">
<h3><a :href="'/author-profile/'+user.uuid+'/'">{{user.full_name}}</a></h3>
<span>129.301 ETH</span>
</div>
</div>
</div>

</div>
</div>
</div>

<div class="auctions-area pb-100" v-if="liveAuctions.length">
<div class="container">
<div class="row align-items-center">
 <div class="col-lg-8 col-md-8">
<div class="section-title">
<h2>Live Auctions</h2>
</div>
</div>
<div class="col-lg-4 col-md-4">
<div class="auction-btn text-end">
<a href="/pages/about-us/" class="default-btn border-radius-5">Explore More</a>
</div>
</div>
</div>
<div class="auctions-slider owl-carousel owl-theme pt-45">

<div class="auction-item" v-for="item in liveAuctions">
<div class="auction-item-img">
<a :href="'/items/'+item.uuid+'/'">
<img :src="api_baseurl+item.featured_image" :alt="item.title" v-if="item.featured_image">
<img src="../assets/dab/images/auctions/auctions-img.jpg" :alt="item.title" v-else>
</a>
<div class="auction-item-content">
<div class="auction-left">
<span v-if="item.price==0">Start Bid</span>
<h3>{{convertCrypto(item.price,item.currency_code,'ETH')}} ETH</h3>
</div>
<div class="auction-right" v-if="item.price==0">
<h3>Remaining Time</h3>
<div class="auction-text" data-countdown="2021/10/10"></div>
</div>
</div>
</div>
<div class="content">
<div class="auction-item-list">
<div class="auction-item-list-img">
<a :href="'/author-profile/'+item.user_uuid+'/'">
<img :src="api_baseurl+item.user_photo" :alt="item.user_full_name" v-if="item.user_photo" style="width:50px !important;height:50px !important;">
<img src="../assets/dab/images/auctions/auctions-user1.jpg" :alt="item.user_full_name" style="width:50px !important;height:50px !important;" v-else>
</a>
<i class="ri-check-line"></i>
</div>
<h3><a :href="'/items/'+item.uuid+'/'">{{item.title}}</a></h3>
<span>Created by <a :href="'/author-profile/'+item.user_uuid">@{{item.user_full_name}}</a></span>
</div>
<a :href="'/author-profile/'+item.user_uuid" class="auction-item-btn"><i class="ri-arrow-right-line"></i></a>
<button type="button" class="default-btn border-radius-5" v-on:click="goto('/items/'+item.uuid+'/')">Place Bid</button>
</div>
</div>

</div>
</div>
</div>


<div class="featured-area pt-100 pb-70" v-if="featuredAssets.length">
<div class="container">
<div class="tab featured-tab-area">
<div class="row align-items-center">
<div class="col-lg-6 col-md-4">
<div class="section-title">
<h2>Featured Assets</h2>
</div>
</div>
<div class="col-lg-6 col-md-8">
<ul class="tabs">
<li>
<a href="#">
All
</a>
</li>
</ul>
</div>
</div>
<div class="tab_content current active pt-45">
<div class="tabs_item current">
<div class="row justify-content-center">
<div class="col-lg-3 col-md-6" v-for="item in featuredAssets">
<div class="featured-item">
<div class="featured-item-img">
<a :href="'/items/'+item.uuid+'/'">
<img :src="api_baseurl+item.featured_image" :alt="item.title" v-if="item.featured_image">
<img src="../assets/dab/images/featured/featured-img1.jpg" :alt="item.title" v-else>
</a>
<div class="featured-user">
<a :href="'/author-profile/'+item.user_uuid" class="featured-user-option">
<img :src="api_baseurl+item.user_photo" :alt="item.user_full_name" v-if="item.featured_image">
<img src="../assets/dab/images/featured/featured-user1.jpg" :alt="item.user_full_name" v-else>
<span>Created by @{{item.user_full_name}}</span>
</a>
</div>
<button type="button" class="default-btn border-radius-5" v-if="item.price==0">Place Bid</button>
<button type="button" class="default-btn border-radius-5" v-else>Buy Now</button>
<div class="featured-item-clock" data-countdown="2021/09/09" v-if="item.price==0"></div>
</div>
<div class="content">
<h3><a :href="'/items/'+item.uuid+'/'">{{item.title}}</a></h3>
<div class="content-in">
<span>{{convertCrypto(item.price,item.currency_code,'ETH')}} ETH </span>
<h4></h4>
</div>
<div class="featured-content-list" style="display:none;">
<ul>
 <li><img src="../assets/dab/images/featured/featured-user1.jpg" alt="Images"></li>
<li><img src="../assets/dab/images/featured/featured-user2.jpg" alt="Images"></li>
<li class="title">10+ People Placed Bid</li>
</ul>
<p><i class="ri-heart-line"></i> 122</p>
</div>
</div>
</div>
</div>

</div>
</div>

</div>
</div>
</div>
</div>


<div class="create-area pt-100 pb-70">
<div class="container">
<div class="section-title text-center">
<h2>Create And Sell Your NFTs</h2>
</div>
<div class="row align-items-center pt-45">
<div class="col-lg-6">
<div class="create-img">
<img src="../assets/dab/images/create/create-img.png" alt="Images">
</div>
</div>
<div class="col-lg-6">
<div class="create-card-right pl-20">
<div class="row justify-content-center">
<div class="col-lg-6 col-6">
<div class="create-card">
<img src="../assets/dab/images/create/create-icon1.png" alt="Images">
<h3><a href="/add-wallet/">Set Up Your Wallet</a></h3>
<p>connect wallet by clicking the wallet icon in the top right corner. Learn about the wallets <a href="/add-wallet/"> we support.</a></p>
</div>
</div>
<div class="col-lg-6 col-6">
<div class="create-card">
<img src="../assets/dab/images/create/create-icon2.png" alt="Images">
<h3><a href="/create-collection/">Create Your Collection</a></h3>
<p>Click Create and Add social links, a description, profile & banner images, and set a secondary sales fee.</p>
</div>
</div>
<div class="col-lg-6 col-6">
<div class="create-card">
<img src="../assets/dab/images/create/create-icon3.png" alt="Images">
<h3><a href="/create-collection/"> Add Your NFTs</a></h3>
<p>Upload your work (image, video, audio, or 3D art), add a title and description, and customize your NFTs</p>
</div>
</div>
<div class="col-lg-6 col-6">
 <div class="create-card">
<img src="../assets/dab/images/create/create-icon4.png" alt="Images">
<h3><a href="/discover-assets/">List Them For Sale</a></h3>
<p>Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your NFTs</p>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="author-area author-area-bg pt-100 pb-70" v-if="topAuthors.length">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-8">
<div class="section-title">
<h2>Top Authors</h2>
</div>
</div>
<div class="col-lg-4">
<div class="author-btn text-end">
<a href="/pages/about-us/" class="default-btn border-radius-5">Explore More</a>
</div>
</div>
</div>
<div class="row pt-45 justify-content-center">
<div class="col-lg-3 col-6" v-for="user in topAuthors">
<div class="author-card">
<a :href="'/author-profile/'+user.uuid+'/'">
<img :src="api_baseurl+user.featured_item_image" :alt="user.full_name" v-if="user.featured_item_image">
<img src="../assets/dab/images/author/author-img1.jpg" :alt="user.full_name" v-else>
</a>
<div class="content">
<div class="author-user">
<img :src="api_baseurl+user.photo_thumbnail" :alt="user.full_name" v-if="user.photo">
<img src="../assets/dab/images/author/author-img1.jpg" :alt="user.full_name" v-else>
<i class="ri-check-line"></i>
</div>
<h3><a :href="'/author-profile/'+user.uuid+'/'">{{truncate(user.full_name,40,'...')}}</a></h3>
<span>@{{truncate(user.username,40,'...')}}</span>
<div class="author-content" v-if="false">
<div class="content-left">
<span>Followers</span>
<h4>2941</h4>
</div>
<div class="content-right">
Follow
<ul class="author-social">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>

</div>
</div>
<div class="author-area-shape">
<img src="../assets/dab/images/author/author-shape.png" alt="Images">
</div>
</div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      biz_resp_data: "",
      metamask_address: "",
      msg: "This is demo net work",
      trendingArtworks:[],
      liveAuctions:[],
      featuredAssets:[],
      topAuthors:[],
      hotCollections:[],
      topSellers:[],
      api_baseurl:"",
      rate_amount:1,
    }
  },
  filters: {
    toCurrency(value) {
        if (typeof value !== "number") {
            return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        });
        return formatter.format(value);
    }
  },
  methods: {
    async processBizname() {
      this.$store.commit('setIsLoading', true)
      await axios
        .get('/api/process-bizname/', {
          params: {
            bizname: this.bizname
          }
        })
        .then(response => {
          this.biz_resp_data = response.data
          console.log(this.biz_resp_data)
        })
        .catch(error => {
          console.log(error)
        })
        this.$store.commit('setIsLoading', false)
    },
    async getMiscData() {
      this.$store.commit('setIsLoading', true)

      await axios
        .get('/api/misc-data/')
        .then(response => {
          this.$store.commit('setMiscData', response.data)
          this.mainMenus = response.data.menus.main_menus
          this.$store.state.mainMenus = response.data.menus.main_menus
          this.$store.state.footerMenuitems = response.data.menus.footer_menuitems
          this.$store.state.homeSliders = response.data.sliders.home_sliders
          this.$store.state.storages = response.data.storages
        })
        .catch(error => {
          console.log(error)
        })

      this.$store.commit('setIsLoading', false)
    },
    formatPrice(value) {
        //let val = (value/1)
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    goto(url){
		window.location.href = url
	},
	
	async getItemList() {
	  		await axios
	                .get("/api/items/?group=trending&image_sizes=550x590&user_image_sizes=65x65")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setTrendingArtworks', response.data.results);
						this.trendingArtworks=response.data.results
						//this.trendingArtworks=response.data.results
						//localStorage.setItem("trendingArtworks", response.data.results);
						//this.$store.state.trendingArtworks = response.data.results
						//localStorage.setItem("trendingArtworks", JSON.stringify(response.data.results));
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  async getAuctionList() {
	  		await axios
	                .get("/api/items/?group=live_auction&image_sizes=550x670")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setLiveAuctions', response.data.results);
						this.liveAuctions=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	async getFeaturedAssets() {
	  		await axios
	                .get("/api/items/?group=featured&image_sizes=550x590&page_size=4")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setFeaturedAssets', response.data.results);
						this.featuredAssets=response.data.results
						console.log(this.featuredAssets.length)
						//this.trendingArtworks=response.data.results
						//localStorage.setItem("trendingArtworks", response.data.results);
						//this.$store.state.trendingArtworks = response.data.results
						//localStorage.setItem("trendingArtworks", JSON.stringify(response.data.results));
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	async getTopAuthors() {
	  		await axios
	                .get("/api/users/?group=top_authors&image_sizes=65x65&item_image_sizes=550x310")
	                .then(response => {
						console.log(response.data)
						this.$store.commit('setTopAuthors', response.data);
						this.topAuthors=response.data
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	async getTopSellers() {
	  		await axios
	                .get("/api/users/?group=top_sellers&image_sizes=65x65&item_image_sizes=550x310")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setTopSellers', response.data.results);
						this.topSellers=response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
	  	async getHotCollections() {
	  		await axios
	                .get("/api/items/?group=hotcollections&page_size=4")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setHotCollections', response.data.results);
						//this.trendingArtworks=response.data.results
						//this.trendingArtworks=response.data.results
						//localStorage.setItem("trendingArtworks", response.data.results);
						//this.$store.state.trendingArtworks = response.data.results
						//localStorage.setItem("trendingArtworks", JSON.stringify(response.data.results));
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            //this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        //this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	                })
	  	},
 },
  mounted() {
  	//this.$store.commit('showToast', 'Welcome to NFT Marketplace');
  	
    document.title = 'Home | Digital Asset Bank'
    this.api_baseurl= axios.defaults.baseURL
    this.getMiscData()
    this.getItemList()
    this.getAuctionList()
    this.getFeaturedAssets()
    this.getTopAuthors()
    this.getHotCollections()
    this.rate_amount=this.$store.state.rate.rates['ETH']
  },
  computed: {
  		toast() {
  			return this.$store.getters.toast;
  		}
 	},
  updated() {
  	$('.trending-slider').owlCarousel({
	    loop:true,
	    margin:10,
	    dots:false,
	    responsiveClass:true,
	    responsive:{
	        0:{
	            items:1,
	            nav:true
	        },
	        600:{
	            items:3,
	            nav:true 
	        },
	        1000:{
	            items:4,
	            nav:true,
	            loop:false
	        }
	    }
	}),
	$('.auctions-slider').owlCarousel({
	    loop:true,
	    margin:10,
	    responsiveClass:true,
	    responsive:{
	        0:{
	            items:1,
	            nav:false
	        },
	        600:{
	            items:2,
	            nav:false
	        },
	        1000:{
	            items:3,
	            nav:false,
	            loop:false
	        }
	    }
	})
  }
}

</script>