<template>
    <div class="preloader">
		<div class="spinner">
			<div class="dot1"></div>
			<div class="dot2"></div>
		</div>
	</div>
	<div class="modal fade fade-scale searchmodal" id="searchmodal" tabindex="-1" role="dialog">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" data-bs-dismiss="modal">
						<i class="ri-close-line"></i>
					</button>
				</div>
				<div class="modal-body">
					<form class="modal-search-form">
						<input type="search" class="search-field" placeholder="Search...">
						<button type="submit"><i class="ri-search-line"></i></button>
					</form>
				</div>
			</div>
		</div>
	</div>
    <div class="navbar-area">
		<div class="mobile-responsive-nav">
			<div class="container-fluid">
				<div class="mobile-responsive-menu">
					<div class="logo">
						<a to="/"> <img src="./assets/dab/images/logo-2.png" alt="logo">
						</a>
					</div>
				</div>
			</div>
		</div>
	
		<div class="desktop-nav desktop-nav-one nav-area">
			<div class="container-fluid">
				<nav class="navbar navbar-expand-md navbar-light ">
					<a class="navbar-brand" href="/"> <img
						src="./assets/dab/images/logo.png" alt="Logo">
					</a>
					<div class="nav-widget-form">
						<form class="search-form" action="/search/">
							<input type="search" name="query" class="form-control"
								placeholder="Search items, Creators ">
							<button type="submit">
								<i class="ri-search-line"></i>
							</button>
						</form>
					</div>
					<div class="collapse navbar-collapse mean-menu"
						id="navbarSupportedContent">
						<ul class="navbar-nav m-auto">
							<li class="nav-item"><a href="/" class="nav-link active">
									Home </a></li>
							<li class="nav-item"><a href="#" class="nav-link ">
									Discover <i class="ri-arrow-down-s-line"></i>
							</a>
								<ul class="dropdown-menu">
									<li class="nav-item" v-if="false">
										<router-link to="/live-auction/" class="nav-link">Live Auction</router-link>
									</li>
									<li class="nav-item">
										<router-link to="/discover-assets/" class="nav-link">Discover Assets</router-link>
									</li>
								</ul></li>
							<li class="nav-item">
								<a href="#" class="nav-link" v-if="isWalletConnected">
									{{truncate(metamask_address,20, '...')}} <i class="ri-user-line"></i> 
								</a>
								<a href="#" class="nav-link" v-else>
									<i class="ri-user-line"></i>
								</a>
								
								<ul class="dropdown-menu">
									<li class="nav-item"><router-link to="/author-profile/" target="_blank">Author Profile</router-link></li>
									<li class="nav-item"><router-link to="/create-collection/" target="_blank">Create Collection</router-link></li>
									<li class="nav-item" v-if="isWalletConnected"><a href="#" v-on:click="disconnectWallet">Logout</a></li>
								</ul>
							</li>
							<!--li class="nav-item" v-else>
								<router-link to="/login/" class="nav-link">
									Login <i class="ri-user-line"></i>
								</router-link>
							</li-->
						</ul>
						<div class="others-options">
							<ul class="optional-item-list">
								<li>
									<router-link to="/create-collection/">Create</router-link>
								</li>
								<li v-if="!isWalletConnected"><button type="button" class="default-btn border-radius-5" data-bs-toggle="modal" data-bs-target="#connectWalletModal">Connect Wallet</button></li>
							</ul>
						</div>
					</div>
				</nav>
			</div>
		</div>
		<div class="mobile-nav">
			<div class="search-btn">
				<a class="#" href="#searchmodal" data-bs-toggle="modal"
					data-bs-target="#searchmodal"> <i class="ri-search-line"></i>
				</a>
			</div>
		</div>
		<div class="side-nav-responsive">
			<div class="container-max">
				<div class="dot-menu dot-menu-mt">
					<div class="circle-inner">
						<div class="circle circle-one"></div>
						<div class="circle circle-two"></div>
						<div class="circle circle-three"></div>
					</div>
				</div>
				<div class="container container-mt">
					<div class="side-nav-inner">
						<div class="side-nav justify-content-center align-items-center">
							<div class="side-nav-item">
								<ul class="optional-item-list">
									<li>
									<router-link to="/create-collection/">Create</router-link>
									</li>
									<li><a href="/add-wallet/" class="active">Connect
											Wallet</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<router-view/>
	
	<footer class="footer-area pt-100 pb-70">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 col-sm-8">
					<div class="footer-widget">
						<div class="footer-logo">
							<a href="/"> <img src="./assets/dab/images/footer-logo.png"
								alt="Footer Logo">
							</a>
						</div>
						<p>the world's digital asset creation and storage platform</p>
						<p>Address: 27 Old Gloucester Street, LONDON, WC1N 3AX</p>
						<p>Email: hi@bitcrome.com</p>
					</div>
				</div>
				<div class="col-lg-2 col-sm-4">
					<div class="footer-widget ps-5">
						<h3>Marketplace</h3>
						<ul class="footer-list">
							<li v-if="false">
							<router-link to="/live-auction/" target="_blank">Live Auction</router-link>
							</li>
							<li><router-link to="/discover-assets/" target="_blank">Discover Assets</router-link>
							</li>
						</ul>
					</div>
				</div>
				<div class="col-lg-2 col-sm-4">
					<div class="footer-widget ps-5">
						<h3>My Account</h3>
						<ul class="footer-list">
							<li><router-link to="/author-profile/" target="_blank">Author Profile</router-link></li>
							<li><router-link to="/create-collection/" target="_blank">Create Collection</router-link></li>
							
						</ul>
					</div>
				</div>
				<div class="col-lg-2 col-sm-4">
					<div class="footer-widget ps-5">
						<h3>Resources</h3>
						<ul class="footer-list">
							<li><a href="/pages/help-support/" target="_blank"> Helps &
									Support </a></li>
							<li><a href="/blog/" target="_blank"> Our Blog </a></li>
						</ul>
					</div>
				</div>
				<div class="col-lg-2 col-sm-4">
					<div class="footer-widget ps-5">
						<h3>Company</h3>
						<ul class="footer-list">
							<li><a href="/pages/about-us/" target="_blank"> About Us </a></li>
							<li>
							<router-link to="/contact-us/" target="_blank">Contact Us</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-shape">
			<div class="footer-shape1">
				<img src="./assets/dab/images/shape/shape-bg.png" alt="Images">
			</div>
			<div class="footer-shape2">
				<img src="./assets/dab/images/shape/shape1.png" alt="Images">
			</div>
			<div class="footer-shape3">
				<img src="./assets/dab/images/shape/shape2.png" alt="Images">
			</div>
		</div>
	</footer>
	<div class="copyright-area">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-8">
					<div class="copy-right-text">
						<p>@ 2021 BITCROME LTD. All Rights Reserved.</p>
						<ul class="copy-right-list">
							<li><a href="/pages/terms-conditions/" target="_blank"> Terms
									& Conditions </a></li>
							<li><a href="/pages/privacy-policy/" target="_blank">
									Privacy Policy </a></li>
						</ul>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="copy-right-social">
						<ul class="social-link">
							<li><a href="https://www.facebook.com/" target="_blank">
									<i class="ri-facebook-fill"></i>
							</a></li>
							<li><a href="https://www.instagram.com/" target="_blank">
									<i class="ri-instagram-fill"></i>
							</a></li>
							<li><a href="https://twitter.com/" target="_blank"> <i
									class="ri-twitter-fill"></i>
							</a></li>
							<li><a href="https://www.linkedin.com/" target="_blank">
									<i class="ri-linkedin-fill"></i>
							</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	 <toast :show="toast.show" :text="toast.text" @hide-toast="hideToast"></toast>
	  <vue-metamask ref="metamask"
            userMessage="msg" 
            @onComplete="onComplete" 
            :initConnect="false"
        >
        </vue-metamask>
    <!-- Modal -->
     
	<div  class="modal fade" id="connectWalletModal" tabindex="-1" aria-labelledby="connectWalletModal" aria-hidden="true">
	  <div class="modal-dialog" style="border-radius: 12px;display: grid;grid-template-columns: repeat(auto-fit,minmax(320px,1fr));max-width: 500px;min-width: fit-content;max-height: 100%;overflow: auto;">
	    <div class="modal-content">
	      <div class="modal-body">
	      	<div class="modal-card" style="">
	      	<div class="wallet-provider-container" style="text-align:center;cursor:pointer;" v-on:click="submitForm">
	      		<div style="width: 45px;height: 45px;display:inline-flex;" class="wallet-icon">
	      			<img style="width: 100%;height: 100%;" src="./assets/img/ui/metamask.svg" alt="Metamask"/>
	      		</div>
	      		<h2>MetaMask</h2>
	      		<p>Connect to your MetaMask Wallet</p>
	      	</div>
	      	</div>
	      </div>
	      <div class="modal-footer" style="text-align:center;justify-content:center !important">
	      	<div>
	    	<p>Download <a href="https://metamask.io/download/">here</a> if Metamask does not exist.</p>
	    	</div>
	    </div>
	    </div>
	  </div>
	</div>    
	
</template>

<script>
import VueMetamask from 'vue-metamask';
import Toast from '@/components/Toast.vue';
import axios from 'axios'
import config from './config.js';

export default {
  components: {
  	VueMetamask,
  	Toast
  },
  data() {
    return {
      showMobileMenu: false,
      cart: {
        items: []
      },
      metamaskWallet:{},
      metamask_address: "",
      metamask_type:"",
      isWalletConnected:false,
      isAuthenticated:false,
      accessToken:"",
      refreshToken:"",
    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')
    this.accessToken = JSON.parse(localStorage.getItem("accessToken"));
    
    const token = this.$store.state.token
    console.log(token)
    if (token) {
        //axios.defaults.headers.common['Authorization'] = "Token " + token
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
    } else {
        axios.defaults.headers.common['Authorization'] = ""
    }
  },
  
  mounted() {
	//this.$toast.show(`Hey! I'm here`);
	//this.$toast.success(`Hey! I'm here`);
	//this.$toast.error(`Hey! I'm here`);
	//this.$toast.warning(`Hey! I'm here`);
	//this.$toast.info(`Hey! I'm here`);
	
	// Close all opened toast after 3000ms
	//setTimeout(this.$toast.clear, 3000);
	
  	if (window.ethereum) {
  	} else if (window.web3) {
  	} else {	
  		//alert(window.ethereum);
  		this.$toast.info('Non-Ethereum browser detected. You should consider trying MetaMask. Support Google Chrome has Metamask extension.');
  		//this.message="Non-Ethereum browser detected. You should consider trying MetaMask!";
  		alert('Non-Ethereum browser detected. You should consider trying MetaMask. Support Google Chrome has Metamask extension.');
  	}
    this.accessToken = JSON.parse(localStorage.getItem("accessToken"));
    this.refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
    if (this.accessToken) {
    	//verifyToken(this.accessToken,this.refreshToken);
    }
  	this.isWalletConnected = JSON.parse(localStorage.getItem("isWalletConnected"));
  	this.isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));
  	this.metamaskWallet = JSON.parse(localStorage.getItem("metamaskWallet"));
    if(this.metamaskWallet){
    	this.metamask_address = this.metamaskWallet['metaMaskAddress'];
    }
    //this.getItemList()
  	this.getCoinbase()
    this.cart = this.$store.state.cart
   ;$('.mean-menu').meanmenu({meanScreenWidth:"991"});$(window).on('scroll',function(){if($(this).scrollTop()>120){$('.navbar-area').addClass("is-sticky");}
else{$('.navbar-area').removeClass("is-sticky");}});$(".side-nav-responsive .dot-menu").on("click",function(){$(".side-nav-responsive .container-max .container").toggleClass("active");});$('.banner-item-slider').owlCarousel({loop:true,margin:30,items:1,nav:false,dots:true,autoplay:true,autoplayHoverPause:true,})
//$('.trending-slider').owlCarousel({loop:true,margin:15,nav:true,dots:false,autoplay:true,autoplayHoverPause:true,responsive:{0:{items:1},576:{items:2,},1000:{items:3},1200:{items:4},},navText:["<i class='ri-arrow-left-s-line'></i>","<i class='ri-arrow-right-s-line'></i>"]})
//$('.auctions-slider').owlCarousel({loop:true,margin:15,nav:false,dots:true,autoplay:true,autoplayHoverPause:true,responsive:{0:{items:1},768:{items:2,},1000:{items:3},},})
$('.auctions-slider-two').owlCarousel({loop:true,margin:15,nav:false,dots:true,autoplay:true,autoplayHoverPause:true,responsive:{0:{items:1},576:{items:2,},1000:{items:3},},})
$('.testimonial-slider').owlCarousel({loop:true,margin:30,items:1,nav:false,dots:true,autoplay:true,autoplayHoverPause:true,})
$('.testimonial-slider-two').owlCarousel({loop:true,margin:30,items:1,nav:false,dots:true,autoplay:true,autoplayHoverPause:true,})
$('.tab ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');$('.tab ul.tabs li a').on('click',function(g){var tab=$(this).closest('.tab'),index=$(this).closest('li').index();tab.find('ul.tabs > li').removeClass('current');$(this).closest('li').addClass('current');tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq('+index+')').slideUp();tab.find('.tab_content').find('div.tabs_item:eq('+index+')').slideDown();g.preventDefault();});$('[data-countdown]').each(function(){var $this=$(this),finalDate=$(this).data('countdown');$this.countdown(finalDate,function(event){$this.html(event.strftime('%D:%H:%M:%S'));});});$('.promoted').find('.promoted-title').on('click',function(){$(this).toggleClass('active');$(this).next().slideToggle('fast');$('.promoted-content').not($(this).next()).slideUp('fast');$('.promoted-title').not($(this)).removeClass('active');});$(window).on('load',function(){$(".preloader").fadeOut(500);});function makeTimer(){var endTime=new Date("October 30, 2021 17:00:00 PDT");var endTime=(Date.parse(endTime))/1000;var now=new Date();var now=(Date.parse(now)/1000);var timeLeft=endTime-now;var days=Math.floor(timeLeft/86400);var hours=Math.floor((timeLeft-(days*86400))/3600);var minutes=Math.floor((timeLeft-(days*86400)-(hours*3600))/60);var seconds=Math.floor((timeLeft-(days*86400)-(hours*3600)-(minutes*60)));if(hours<"10"){hours="0"+hours;}
if(minutes<"10"){minutes="0"+minutes;}
if(seconds<"10"){seconds="0"+seconds;}
$("#days").html(days+"<span>Days</span>");$("#hours").html(hours+"<span>Hours</span>");$("#minutes").html(minutes+"<span>Minutes</span>");$("#seconds").html(seconds+"<span>Seconds</span>");}
setInterval(function(){makeTimer();},300);$(".newsletter-form").validator().on("submit",function(event){if(event.isDefaultPrevented()){formErrorSub();submitMSGSub(false,"Please enter your email correctly");}else{event.preventDefault();}});function callbackFunction(resp){if(resp.result==="success"){formSuccessSub();}
else{formErrorSub();}}
function formSuccessSub(){$(".newsletter-form")[0].reset();submitMSGSub(true,"Thank you for subscribing!");setTimeout(function(){$("#validator-newsletter").addClass('hide');},4000)}
function formErrorSub(){$(".newsletter-form").addClass("animated shake");setTimeout(function(){$(".newsletter-form").removeClass("animated shake");},1000)}
function submitMSGSub(valid,msg){if(valid){var msgClasses="validation-success";}else{var msgClasses="validation-danger";}
$("#validator-newsletter").removeClass().addClass(msgClasses).text(msg);}
$(".newsletter-form").ajaxChimp({url:"/subscribe/post?u=60e1ffe2e8a68ce1204cd39a5&amp;id=42d6d188d9",callback:callbackFunction});$('body').append("<div class='go-top'><i class='ri-arrow-up-s-line'></i></div>");$(window).on('scroll',function(){var scrolled=$(window).scrollTop();if(scrolled>600)$('.go-top').addClass('active');if(scrolled<600)$('.go-top').removeClass('active');});$('.go-top').on('click',function(){$('html, body').animate({scrollTop:'0',},500);});
  },
  methods: {
    async signup() {
    		axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            
    		const formData = {
            	user: {
			        full_name: accounts[0],
			    },
                public_address: accounts[0]
            }

            await axios
                .post("/api/metamask/", formData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                    $('#connectWalletModal').modal('toggle');
                    const token = response.data.access
                    this.$store.commit('setToken', token)
                    this.$store.commit('showToast', 'Login successfully!');
                    
                    //axios.defaults.headers.common["Authorization"] = "Token " + token
                    axios.defaults.headers.common['Authorization'] = "Bearer " + token
                    this.$store.commit('setIsWalletConnected',true);
					this.$store.commit('setIsAuthenticated',true);
					this.$store.commit('setUser',response.data.user)
					//localStorage.setItem("user", response.data.user)
					
                    const toPath = this.$route.query.to || '/'
                    this.$router.push(toPath)
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${error.response.data[property]}`)
                            this.$store.commit('showToast', `${error.response.data[property]}`);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    	},
    	
    	async login(nonce) {
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            //let msg = web3.utils.keccak256('Hello from Bitcrome!');
            let public_address =  accounts[0];
            let msg = 'I am signing my one-time nonce '+nonce
            let sign = await web3.eth.personal.sign(msg, public_address);
            
            //const sign=web3.personal.sign(web3.utils.toUtf8("Hello from Toptal!"), accounts[0], console.log);
            //console.log(sign)
            
            const formData = {
            	signature:sign,
            }
			
            await axios
                .post("/api/metamask/login/"+public_address, formData)
                .then(response => {
                	console.log(response)
                    console.log(response.data)
                    $('#connectWalletModal').modal('toggle');
                    
                    const token = response.data.access
                    this.$store.commit('setToken', token)
                    this.$store.commit('showToast', 'Login successfully!');
                    
                    //axios.defaults.headers.common["Authorization"] = "Token " + token
					axios.defaults.headers.common['Authorization'] = "Bearer " + token
                    
                    this.$store.commit('setIsWalletConnected',true);
					this.$store.commit('setIsAuthenticated',true);
					this.$store.commit('setUser',response.data.user)
                    this.$store.commit('setRefreshToken',response.data.refresh)
                    this.$store.commit('setAccessToken',response.data.access)
                    this.metamask_address = this.metamaskWallet['metaMaskAddress'];
                    
                    this.isWalletConnected = true;
                    this.$store.state.user = response.data.user
                    localStorage.setItem("token", token)
					this.$store.state.isWalletConnected=true;
					
                    
					//localStorage.setItem("user", response.data.user)
                    const toPath = this.$route.query.to || '/'
                    this.$router.push(toPath)
                })
                .catch(error => {
                	this.signup()
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${error.response.data[property]}`)
                            this.$store.commit('showToast', `${error.response.data[property]}`);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
        },
	async submitForm() {
        	//this.$refs.metamask.init();
        	
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            let accounts = await web3.eth.getAccounts();
            //let msg = web3.utils.keccak256('Hello from Bitcrome!');
            let public_address =  accounts[0];
            await axios.get("/api/metamask/"+public_address)
            .then(response => {
            	console.log(response.data.nonce) 	
            	this.login(response.data.nonce)
            	
            }).catch(error => {
            	this.signup()
            	console.log(error)
            })
    },
    async startLogin() {
    const publicAddress =this.metamask_address;
    
     if (window.ethereum) {
	  try {
	    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
	    if(accounts.length >0) {
	    		$('#connectWalletModal').modal('toggle');
	    		//localStorage.setItem("isWalletConnected",true);
	    		//this.$store.commit('setIsWalletConnected',true);
		        this.$store.commit('setIsWalletConnected',true);
				this.$store.commit('setIsAuthenticated',true);
				this.$store.commit('setUser',response.data.user)
				
	    	}
	  } catch (error) {
	    if (error.code === 4001) {
	      // User rejected request
	    }
	
	    console.error(error);
	  }
	  }
	},
    async disconnectWallet() {
    	console.log(web3);
    	console.log(web3.currentProvider);
		this.metamask_address="";
		this.$store.commit('setIsWalletConnected',false);
		this.$store.commit('setIsAuthenticated',false);
		this.$store.commit('removeToken','');
		
		this.$store.commit('setMetamaskWallet',{});
		axios.defaults.headers.common["Authorization"] = "";
        localStorage.removeItem("token");
            
		/*
		await window.ethereum.request({
	        method: "wallet_requestPermissions",
	        params: [{
	            eth_accounts: {}
	        }]
	    });
	    */
    },    
    async connectWallet() {
    	const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
	    if(accounts.length >0) {
	        //await ethereum.enable();
	        $('#connectWalletModal').modal('toggle');
			this.$store.commit('setIsWalletConnected',true);
			this.$store.commit('setIsAuthenticated',true);
			
	      } else{
	      	this.$store.commit('setIsWalletConnected',false);
			this.$store.commit('setIsAuthenticated',false);
	        this.Log(
	          this.MetamaskMsg.USER_DENIED_ACCOUNT_AUTHORIZATION,
	          "USER_DENIED_ACCOUNT_AUTHORIZATION"
	        );
	      }
	      
    },
  	onComplete(data){
		console.log(this.isWalletConnected);
  		if(this.isWalletConnected){
  			console.log("Wallet Connected");
  		}
  		
  		if (true) {
	    	console.log('data:', data);
	    	if(!data['metaMaskAddress']) {
	    		this.$store.commit('showToast', 'Please download Metamask to mint NFT. Link: https://metamask.io/download.html');
	    	}
	    	console.log({web3: 'Proxy', type: data['type'], metaMaskAddress: data['metaMaskAddress'], message: data['message'], netID: data['netID']});
	    	
	    	this.$store.commit('setMetamaskWallet', {web3: 'Proxy', type: data['type'], metaMaskAddress: data['metaMaskAddress'], message: data['message'], netID: data['netID']});
	    	this.metamask_address=data['metaMaskAddress']
	    	console.log(this.metamask_address)
	    	this.metamask_type=data['type']
	    	console.log(this.metamask_type);
	    	
	    	if ('MAINNET' !== this.metamask_type) {
	    		this.isRightNetwork=false;
	    		this.$store.state.isRightNetwork=false;
	    		this.$store.commit('showToast', 'Please choose Mainnet Network!');
	    	} else {
	    		if (137 == data['netID']) {
	    			this.$store.commit('showToast', 'You are using Polygon Mainnet Network!');
	    			this.isRightNetwork=true;
	    			this.$store.state.isRightNetwork=true;
	    		} else {
	    			this.$store.commit('showToast', 'Please choose Polygon Mainnet Network!');
	    		}
	    		
	    	}
	    	
	    	//this.isRightNetwork=true;
	    	//this.$store.state.isRightNetwork=true;
    	}
    },
  	hideToast() {
  		this.$store.commit("hideToast");
  	},
	async getItemList() {
	  		await axios
	                .get("/api/items/?image_sizes=550x590")
	                .then(response => {
						console.log(response.data.results)
						this.$store.commit('setTrendingArtworks', response.data.results);
						//this.trendingArtworks=response.data.results
						//localStorage.setItem("trendingArtworks", response.data.results);
						//this.$store.state.trendingArtworks = response.data.results
						//localStorage.setItem("trendingArtworks", JSON.stringify(response.data.results));
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                            this.$store.commit('showToast', error.response.data[property]);
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        this.$store.commit('showToast', 'Something went wrong. Please try again');
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
  	async getCoinbase() {
    	await axios
                .get("https://api.coinbase.com/v2/exchange-rates?currency=MATIC")
                .then(response => {
					this.$store.commit('setRate', response.data.data);
					console.log(response.data.data.currency);
					console.log(response.data.data.rates);
					console.log(response.data.data.rates['ETH']);
					console.log(response.data.data.rates['BTC']);
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
    },
  	async logout() {
  			await axios
                .post("/api/v1/token/logout/", {"token":localStorage.getItem("token")})
                .then(response => {
                    
                    axios.defaults.headers.common["Authorization"] = ""
			
		            localStorage.removeItem("token")
		            localStorage.removeItem("username")
		            localStorage.removeItem("userid")
		
		            this.$store.commit('removeToken')
		            this.$store.state.user = ''
		            localStorage.removeItem("user")
		            this.$router.push('/')
		            this.$store.commit('showToast', 'Logout successfully..');
		            
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            //this.errors.push(`${property}: ${error.response.data[property]}`)
                            this.$store.commit('showToast', error.response.data[property]);
                        }
                    } else {
                        //this.errors.push('Something went wrong. Please try again')
                        this.$store.commit('showToast', 'Something went wrong. Please try again');
                        console.log(JSON.stringify(error))
                    }
                })
        },
  },
  computed: {
  	toast() {
  			return this.$store.getters.toast;
  		},
      cartTotalLength() {
          let totalLength = 0

          for (let i = 0; i < this.cart.items.length; i++) {
              totalLength += this.cart.items[i].quantity
          }

          return totalLength
      }
  }
}
</script>
