<template>
<div class="inner-banner inner-bg12">
<div class="container">
<div class="inner-title">
<h3>{{item.title}}</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>Discover</li>
<li>{{item.title}}</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>

<div class="item-details-area pt-100 pb-70">
<div class="container">
<div class="row" v-if="item.transaction">
<div class="col-lg-7">
<div class="item-details-left-side pr-20">
<div class="item-details-img" v-if="item.asset_file_mime_type">
<a v-if="item.asset_file_mime_type.indexOf('video/') !== -1" href="#" v-on:click="showVideo($event)" :data-fileurl="item.asset_file_url" data-bs-toggle="modal" data-bs-target="#exampleModal">
<img :src="api_baseurl+item.featured_image" :alt="item.title">
<span><i class="ri-play-circle-line"></i> Play</span>
</a>
<img v-else :src="api_baseurl+item.featured_image" :alt="item.title">
</div>
<div class="item-details-content" v-if="false">
<h3>History</h3>
 <div class="item-details-into">
<div class="row">
<div class="col-lg-12">
<div class="item-details-card">
<div class="item-details-card-img">
<img src="../assets/dab/images/Item-details/Item-details2.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<div class="item-details-card-content">
<h3>Bid Placed For <b>235 ETH</b></h3>
<span>@Jack</span>
</div>
<div class="work-hours">
4 Hours Ago
</div>
</div>
</div>
<div class="col-lg-12">
<div class="item-details-card">
<div class="item-details-card-img">
<img src="../assets/dab/images/Item-details/Item-details3.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<div class="item-details-card-content">
<h3>Bid Placed For <b>245 ETH</b></h3>
<span>@{{item.user_full_name}}</span>
</div>
<div class="work-hours">
2 Hours Ago
</div>
</div>
</div>
<div class="col-lg-12">
<div class="item-details-card">
<div class="item-details-card-img">
<img src="../assets/dab/images/Item-details/Item-details4.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<div class="item-details-card-content">
<h3>Bid Placed For <b>215 ETH</b></h3>
<span>@Martina</span>
</div>
<div class="work-hours">
2 Hours Ago
</div>
</div>
</div>
<div class="col-lg-12">
<div class="item-details-card">
<div class="item-details-card-img">
<img src="../assets/dab/images/Item-details/Item-details5.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<div class="item-details-card-content">
<h3>Bid Placed For <b>265 ETH</b></h3>
 <span>@Martina</span>
</div>
<div class="work-hours">
10 Hours Ago
</div>
</div>
</div>
<div class="col-lg-12">
<div class="item-details-card">
<div class="item-details-card-img">
<img src="../assets/dab/images/Item-details/Item-details6.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<div class="item-details-card-content">
<h3>Bid Placed For <b>235 ETH</b></h3>
<span>@Martina</span>
</div>
<div class="work-hours">
8 Hours Ago
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-5">
<div class="item-details-dsce">
<div class="section-title">
<h2>Description</h2>
<p>{{item.description}}</p>
</div>
<div class="row">
<div class="col-lg-6 col-6">
<div class="item-details-user">
<h3>Creator</h3>
<div class="content">
<div class="images">
<img :src="api_baseurl+item.user_photo" :alt="item.user_full_name">
<i class="ri-check-line"></i>
</div>
<span>@{{item.user_username}}</span>
</div>
</div>
</div>
</div>
<template v-if="item.transaction">
	<div v-if="item.transaction.txn_hash">
		<h3>Token ID[<a :href="explorer_url+'token/'+contract_address+'?a='+item.transaction.tokenId">{{item.transaction.tokenId}}</a>] BITCROME (BCR)</h3>
	</div>
</template>

<div class="item-details-price">
<template v-if="item.transaction">
<div class="item-details-title" v-if="item.transaction.sale_type=='fixed-price'">
<h3>Price: {{convertCrypto(item.price,item.currency_code,'ETH')}} ETH</h3>
<p>{{item.currency_symbol}}{{item.price}}</p>
<span></span>
 </div>
 </template>
<ul>
<template v-if="item.transaction">
	<div v-for="trait in item.transaction.traits">
		<div v-for="(value,key) in JSON.parse(trait)">
			<li>
			{{key}}
			<b>: {{value}}</b>
			</li>
		</div>
	</div>
</template>
</ul>
</div>
<div class="item-details-user-item" v-if="false">
<div class="images">
<img src="../assets/dab/images/Item-details/Item-details-user4.jpg" alt="Images">
<i class="ri-check-line"></i>
</div>
<div class="content" >
<h3>Jecob Martin</h3>
<span>Item Owner</span>
</div>
</div>
<template v-if="item.transaction">
<div class="item-details-in-content" v-if="item.transaction.sale_type=='timed-auction'">
<div class="item-left">
<span>Auction Ends In</span>
<div class="timer-text" data-countdown="2021/11/11"></div>
</div>
<div class="item-right">
<h3 class="item-remaining">Highest Bid</h3>
<h3 class="item-right-eth">15,00 ETH</h3>
</div>
</div>
</template>
<div class="item-details-btn" v-if="item.transaction">
<template v-if="item.transaction.sale_type!='fixed-price'">
<a href="#" class="default-btn border-radius-50"> Place Bid</a>
</template>
<template v-else>
<button class="default-btn border-radius-50" v-on:click="transferNFT()"> Buy now</button>
</template>
</div>
<div class="item-details-user-area" v-if="false">
<h3>Latest Bids </h3>
<div class="item-details-user-into">
<div class="row">
<div class="col-lg-12">
<div class="item-details-user-card">
<div class="item-details-card-img">
<img src="../assets/dab/images/Item-details/Item-details-user4.jpg" alt="Images">
</div>
<div class="item-details-card-content">
<h3>By <b>Wodee-nel</b></h3>
<span>Bid <b> At 244 ETH</b></span>
</div>
<div class="item-details-card-right">
<h3>246 ETH</h3>
<span>11.49 AM</span>
</div>
</div>
</div>
<div class="col-lg-12">
<div class="item-details-user-card">
<div class="item-details-card-img">
<img src="../assets/dab/images/Item-details/Item-details-user5.jpg" alt="Images">
</div>
<div class="item-details-card-content">
<h3>By <b>Wodee-nel</b></h3>
<span>Bid <b> At 274 ETH</b></span>
</div>
<div class="item-details-card-right">
<h3>266 ETH</h3>
<span>07.49 AM</span>
</div>
</div>
</div>
<div class="col-lg-12">
<div class="item-details-user-card">
<div class="item-details-card-img">
<img src="../assets/dab/images/Item-details/Item-details-user6.jpg" alt="Images">
</div>
<div class="item-details-card-content">
<h3>By <b>Wodee-nel</b></h3>
<span>Bid <b> At 244 ETH</b></span>
</div>
<div class="item-details-card-right">
<h3>256 ETH</h3>
<span>09.49 AM</span>
</div>
</div>
</div>
<div class="col-lg-12">
<div class="item-details-user-card">
<div class="item-details-card-img">
<img src="../assets/dab/images/Item-details/Item-details-user7.jpg" alt="Images">
</div>
<div class="item-details-card-content">
<h3>By <b>Wodee-nel</b></h3>
<span>Bid <b> At 234 ETH</b></span>
</div>
<div class="item-details-card-right">
<h3>246 ETH</h3>
<span>10.49 AM</span>
</div>
</div>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>


<div class="trending-area trending-area-bg-two pt-100 pb-70">
<div class="container">
<div class="row">
<div class="col-lg-8 col-md-6">
<div class="section-title">
<h2>Trending Artwork</h2>
</div>
</div>
<div class="col-lg-4 col-md-6">
<div class="trending-btn text-end">
<a href="item-details.html" class="default-btn border-radius-5">Explore More</a>
</div>
</div>
</div>
<div class="trending-slider owl-carousel owl-theme pt-45">
<div class="trending-item">
<div class="trending-img">
<a href="item-details.html">
<img src="../assets/dab/images/trending/trending-img6.jpg" alt="Images">
</a>
<div class="trending-user">
<a href="author-profile.html" class="trending-user-option">
<img src="../assets/dab/images/trending/trending-user1.jpg" alt="Images">
<span>Created by @Farnil</span>
</a>
</div>
<div class="trending-title">
<span>100 ETH 12/14</span>
<h3>Bid 80 ETH</h3>
</div>
</div>
<div class="content">
<h3>
<a href="item-details.html">
Les Immortal's
</a>
</h3>
<span><i class="ri-heart-line"></i> 340</span>
</div>
</div>
<div class="trending-item">
<div class="trending-img">
<a href="item-details.html">
<img src="../assets/dab/images/trending/trending-img7.jpg" alt="Images">
</a>
<div class="trending-user">
<a href="author-profile.html" class="trending-user-option">
<img src="../assets/dab/images/trending/trending-user2.jpg" alt="Images">
<span>Created by @Anvi</span>
</a>
</div>
<div class="trending-title">
<span>110 ETH 12/14</span>
<h3>Bid 90 ETH</h3>
</div>
</div>
<div class="content">
<h3>
<a href="item-details.html">
Industrial Revolution
</a>
</h3>
<span><i class="ri-heart-line"></i> 365</span>
</div>
</div>
<div class="trending-item">
<div class="trending-img">
<a href="item-details.html">
<img src="../assets/dab/images/trending/trending-img8.jpg" alt="Images">
</a>
<div class="trending-user">
<a href="author-profile.html" class="trending-user-option">
<img src="../assets/dab/images/trending/trending-user3.jpg" alt="Images">
<span>Created by @Anvi</span>
</a>
</div>
<div class="trending-title">
<span>120 ETH 12/14</span>
<h3>Bid 70 ETH</h3>
</div>
</div>
<div class="content">
<h3>
<a href="item-details.html">
Walking on Air
</a>
</h3>
<span><i class="ri-heart-line"></i> 190</span>
</div>
 </div>
<div class="trending-item">
<div class="trending-img">
<a href="item-details.html">
<img src="../assets/dab/images/trending/trending-img4.jpg" alt="Images">
</a>
<div class="trending-user">
<a href="author-profile.html" class="trending-user-option">
<img src="../assets/dab/images/trending/trending-user4.jpg" alt="Images">
<span>Created by @Aiden</span>
</a>
</div>
<div class="trending-title">
<span>100 ETH 12/14</span>
<h3>Bid 80 ETH</h3>
</div>
</div>
<div class="content">
<h3>
<a href="item-details.html">
Tranquility (Modal)
</a>
</h3>
<span><i class="ri-heart-line"></i> 204</span>
</div>
</div>
<div class="trending-item">
<div class="trending-img">
<a href="item-details.html">
<img src="../assets/dab/images/trending/trending-img5.jpg" alt="Images">
</a>
<div class="trending-user">
<a href="author-profile.html" class="trending-user-option">
<img src="../assets/dab/images/trending/trending-user5.jpg" alt="Images">
<span>Created by @Jekob</span>
</a>
</div>
<div class="trending-title">
<span>105 ETH 12/14</span>
<h3>Bid 60 ETH</h3>
</div>
</div>
<div class="content">
<h3>
<a href="item-details.html">
To Infinity
</a>
</h3>
<span><i class="ri-heart-line"></i> 204</span>
</div>
</div>
</div>
</div>
</div>	

<!-- Modal -->
	<div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	  <div class="modal-dialog">
	    <div class="modal-content">
	      <div class="modal-body">
	      	<h2>Preview Video</h2>
	        <video id="video-preview" controls autoplay v-show="showVideoPreview"/>
	      </div>
	      
	      <div class="modal-footer">
	        <button type="button" class="default-btn border-radius-5" data-bs-dismiss="modal">Close</button>
	      </div>
	      
	    </div>
	    
	  </div>
	</div>
</template>

<script>
import VueMetamask from 'vue-metamask';
import axios from 'axios'
import config from '../config.js';
export default {
    name: 'Item',
    components: {
    	VueMetamask,
	},
    data() {
        return {
            item: "",
            quantity: 1,
            user:{},
            rate:1,
            eth_price:{},
            showImagePreview:true,
            showVideoPreview:false,
            
            metamaskWallet:{},
	        metamask_address: "",
	        metamask_type:"",
			
            api_baseurl:"",
            tokenId:0,
            explorer_url: config.explorerUrl,
            contract_address: config.contractAddress,
            file_type:"image",
        }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        this.getItem() 
        this.rate = this.$store.state.rate
        
		this.metamaskWallet = JSON.parse(localStorage.getItem("metamaskWallet"));
	  	console.log(this.metamaskWallet);
	    if(this.metamaskWallet){
	    	this.metamask_address = this.metamaskWallet['metaMaskAddress'];
	    }
	    console.log(this.metamask_addresss);
    },
    methods: {
    	onComplete(data){
	  		console.log("ttttt....");
	    	console.log('data:', data);
	    	if(!data['metaMaskAddress']) {
	    		this.$store.commit('showToast', 'Please download Metamask to mint NFT. Link: https://metamask.io/download.html');
	    	}
	    	console.log({web3: 'Proxy', type: data['type'], metaMaskAddress: data['metaMaskAddress'], message: data['message'], netID: data['netID']});
	    	
	    	this.$store.commit('setMetamaskWallet', {web3: 'Proxy', type: data['type'], metaMaskAddress: data['metaMaskAddress'], message: data['message'], netID: data['netID']});
	    	this.metamask_address=data['metaMaskAddress']
	    	console.log(this.metamask_address)
	    	this.metamask_type=data['type']
	    	console.log(this.metamask_type);
	    	
	    	if ('MAINNET' !== this.metamask_type) {
	    		this.isRightNetwork=false;
	    		this.$store.state.isRightNetwork=false;
	    		this.$store.commit('showToast', 'Please choose Mainnet Network!');
	    	} else {
	    		if (137 !== data['netID']) {
	    			this.$store.commit('showToast', 'Please choose Polygon Mainnet Network!');
	    		} else {
	    			this.$store.commit('showToast', 'Please choose Polygon Mainnet Network!');
	    		}
	    		this.isRightNetwork=true;
	    		this.$store.state.isRightNetwork=true;
	    	}
	    	
	    	this.isRightNetwork=true;
	    	this.$store.state.isRightNetwork=true;
	    },
    	async transferNFT() {
			//const API_URL="https://eth-ropsten.alchemyapi.io/v2/mkmpYPZHBR0zola6xOzx4A8E5XCcrbcI"
			const API_URL=config.apiUrl
			const PUBLIC_KEY = "0x28F90bA1353b21Ca3fac3Ab665b1DBDF8E3FB539"
			const PRIVATE_KEY = "f9ef743b5b510b8cddf6fdbfcdc57c07457ddf028b67e00c6a4e0f90ce444e79"
			const MYWALLET_ADDRESS = this.metamaskWallet['metaMaskAddress']
			console.log(MYWALLET_ADDRESS);
			
			const { createAlchemyWeb3 } = require("@alch/alchemy-web3")
			const web3 = createAlchemyWeb3(API_URL)
			let allow_buy_price = '0';
			let tokenId = '0';
			if (this.item.transaction) {
				allow_buy_price = this.item.transaction.allow_buy_price;
				tokenId = this.item.transaction.tokenId;
			}
			//const fValue = 1000000000000000000*this.item.price/this.$store.state.rate.rates['ETH'];
			//const WEI_PRICE = parseFloat(1000000000000000000*this.item.price/this.$store.state.rate.rates['ETH']).toFixed(0).toString();
			//const ETH_PRICE = web3.utils.toWei(WEI_PRICE, 'ether'); 
			//const ETH_PRICE = web3.utils.toHex(web3.utils.toWei(fValue, 'ether'));
			const ETH_PRICE = allow_buy_price;
			//const contract = require("../../artifacts/contracts/MyNFT.sol/MyNFT.json")
			const contract = require("../../artifacts/contracts/BITCROME.sol/BITCROME.json")
			console.log(JSON.stringify(contract.abi))
			//const contractAddress = "0xd0F6413e284Ee26D5dDCd9F3A2e1b90A0E6215EA" is first 
			const contractAddress = config.contractAddress
			const nftContract = new web3.eth.Contract(contract.abi, contractAddress)
			const nonce = await web3.eth.getTransactionCount(MYWALLET_ADDRESS, "latest") //get latest nonce
			console.log(ETH_PRICE)
			//the transaction
			nftContract.methods.buy(tokenId).send({from:MYWALLET_ADDRESS,value:ETH_PRICE}).on('transactionHash', function(hash){
				console.log(hash);
				            console.log(
				              "The hash of your transaction is: ",
				              hash,
				              "\nCheck Alchemy's Mempool to view the status of your transaction!"
				            )
			})
			
			return 1
			
			const method_data = nftContract.methods.buy(tokenId).encodeABI()
				const tx = {
					    from: MYWALLET_ADDRESS,
			    		to: contractAddress,
					    nonce: nonce,
					    gas: 500000,
					    data: method_data,
					}
					
					console.log(tx)
					await web3.eth.sendTransaction(
			        tx,
			        function (err, hash) {
			          if (!err) {
				            this.message = "The hash of your transaction is: "+hash;
				            console.log(
				              "The hash of your transaction is: ",
				              hash,
				              "\nCheck Alchemy's Mempool to view the status of your transaction!"
				            )
				            const interval = setInterval(function() {
						        console.log("Attempting to get transaction receipt...");
						        web3.eth.getTransactionReceipt(hash, function(err, rec) {
						          if (rec) {
						            console.log(rec);
						            clearInterval(interval);
						            //nftContract.methods.buy(tokenId).send({from:MYWALLET_ADDRESS,value:ETH_PRICE});
						            
						            const item = JSON.parse(localStorage.getItem("item"))
						            const user = JSON.parse(localStorage.getItem("user"))
						            console.log(item)
						            console.log(item.uuid)
						            const formData = {
										    active:1,
										    id:item.id,
										    uuid:item.uuid,
										    user:user.id,
						            }
						            axios
						                .put("/api/items/"+item.uuid+"/", formData)
					                .then(response => {
					                	console.log(response.data);
					                	localStorage.setItem("item", JSON.stringify(response.data));
					                    this.message = "Update the item successfully!";
					                })
					                .catch(error => {
					                    if (error.response) {
					                        for (const property in error.response.data) {
					                            this.$store.commit('showToast', error.response.data[property]);
					                        }
					                    } else {
					                        this.$store.commit('showToast', 'Something went wrong. Please try again');
					                        console.log(JSON.stringify(error))
					                    }
					                }) 
						          }
						        });
						      }, 1000);
				            
				            
				            
				          } else {
				            console.log(
				              "Something went wrong when submitting your transaction:",
				              err
				            )
				          }
				        }
				      )
			
		},
    	showVideo(event) {
    		let video = document.getElementById('video-preview');
			let reader = new FileReader();
			console.log( this.item.asset_file_url )
			video.src=axios.defaults.baseURL+this.item.asset_file_url
			this.showImagePreview = false;
			this.showVideoPreview = true;
			/*reader.readAsDataURL( new File([await (await fetch(event.target.dataset.fileurl)).blob()], event.target.dataset.fileurl) );
			reader.addEventListener('load', function(){
				this.showImagePreview = false;
				this.showVideoPreview = true;
				video.src = reader.result;
			}.bind(this), false);*/
    	},
        async getItem() {
        	this.$store.commit('setIsLoading', true)
            const category_slug = this.$route.params.category_slug
            const id = this.$route.params.id

            await axios
                .get(`/api/items/${id}/?image_sizes=730x680&user_image_sizes=65x65`)
                .then(response => {
                	console.log(response.data)
                    this.item = response.data
                    document.title = this.item.title
                    this.eth_price = this.item.price*this.$store.state.rate.rates['ETH']
                    localStorage.setItem("item", JSON.stringify(response.data));
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        addToCart() {
            if (isNaN(this.quantity) || this.quantity < 1) {
                this.quantity = 1
            }

            const item = {
                product: this.product,
                quantity: this.quantity
            }

            this.$store.commit('addToCart', item)
        }
    }
}
</script>