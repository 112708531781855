<template>
<div class="inner-banner inner-bg3">
<div class="container">
<div class="inner-title">
<h3>Discover Digital Assets</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>Discover</li>
<li>Discover Digital Assets</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>


<div class="featured-area pt-100 pb-70">
<div class="container">
<div class="tab featured-tab-area">
<div class="row align-items-center">
<div class="col-lg-6 col-md-4">
<div class="section-title">
<h2>Discover Assets</h2>
</div>
</div>
<div class="col-lg-6 col-md-8">
</div>
</div>
<div class="tab_content current active pt-45">
<div class="tabs_item current">
<div class="row justify-content-center">

<div class="col-lg-3 col-md-6" v-for="item in assets">
<div class="featured-item">
<div class="featured-item-img">
<a :href="'/items/'+item.uuid+'/'">
<img :src="api_baseurl+item.featured_image" :alt="item.title" v-if="item.featured_image">
<img src="../assets/dab/images/featured/featured-img1.jpg" :alt="item.title" v-else>

</a>
<div class="featured-user">
<a :href="'/author-profile/'+item.user_uuid" class="featured-user-option">
<img :src="api_baseurl+item.user_photo" :alt="item.user_full_name" v-if="item.user_photo">
<img src="../assets/dab/images/featured/featured-user1.jpg" :alt="item.user_full_name" v-else>

<span>Created by @{{truncate(item.user_full_name,20,'...')}}</span>
</a>
</div>
<button type="button" class="default-btn border-radius-5" v-if="item.price==0" v-on:click="goto('/items/'+item.uuid+'/')">Place Bid</button>
<button type="button" class="default-btn border-radius-5" v-else v-on:click="goto('/items/'+item.uuid+'/')">Buy Now</button>
<div class="featured-item-clock" data-countdown="2021/09/09"></div>
</div>
<div class="content">
<h3><a :href="'/items/'+item.uuid">{{item.title}}</a></h3>
<div class="content-in">
<span>{{convertCrypto(item.price,item.currency_code,'ETH')}} ETH</span>
<h4 v-if="item.price==0">Bid 80 ETH </h4>
</div>
<div class="featured-content-list" v-if="false">
<ul>
<li><img src="../assets/dab/images/featured/featured-user1.jpg" alt="Images"></li>
<li><img src="../assets/dab/images/featured/featured-user2.jpg" alt="Images"></li>
<li class="title">10+ People Placed Bid</li>
</ul>
<p><i class="ri-heart-line"></i> 122</p>
</div>
</div>
</div>
</div>

</div>
</div>
</div>

<div class="col-lg-12 col-md-12 text-center">
<div class="pagination-area" v-if="num_pages > 1">
<a v-if="showPrev" v-on:click="loadPrev()" class="prev page-numbers">
<i class="ri-arrow-left-s-line"></i>
</a>
<span class="page-numbers current" aria-current="page">{{currentPage}}</span>
<a v-if="showNext" v-on:click="loadNext()" class="next page-numbers">
<i class="ri-arrow-right-s-line"></i>
</a>
</div>
</div>

</div>
</div>
</div>


<div class="involved-area-two pt-100 pb-70">
<div class="container">
<div class="section-title text-center">
<h2>Get Involved</h2>
</div>
<div class="row pt-45">
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-flight-takeoff-line"></i>
</div>
<h3>Join Our <b>Community</b></h3>
<ul class="social-link">
<li>
<a href="https://www.google.com/" target="_blank">
<i class="ri-google-fill"></i>
</a>
</li>
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
<li>
<a href="https://www.linkedin.com/" target="_blank">
<i class="ri-linkedin-fill"></i>
</a>
</li>
</ul>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-checkbox-circle-line"></i>
</div>
<h3>Become A <b>Creator</b></h3>
<a href="/register/" class="default-btn">Register</a>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-heart-2-line"></i>
</div>
<h3>Become A <b>Charity Partner</b></h3>
<a href="/create-collection/" class="default-btn">Express Interest</a>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-discuss-line"></i>
</div>
<h3>Reach Out To <b>Our Team</b></h3>
<a href="/contact-us/" class="default-btn">Contact Now</a>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import axios from 'axios'

export default {
    name: 'DiscoverAsset',
    mounted() {
        document.title = 'Discover Assets'
        this.getAssets()
        this.rate_amount=this.$store.state.rate.amount
        this.api_baseurl= axios.defaults.baseURL
    },
    data() {
      return {
	      assets:[],
	      rate_amount:1,
	      api_baseurl:"",
	      count:0,
	      currentPage:1,
	      page_size:6,
	      showNext:false,
	      showPrev:false,
	      showPagination:false,
	      num_pages:0,
     }
   },
   methods: {
   	  loadPrev: function () {
    	this.currentPage -=1
    	this.getAssets()
      },
      loadNext: function () {
    	this.currentPage +=1
    	this.getAssets()
      },
   	  goto(url){
		window.location.href = url
	  },
      async getAssets() {
      	this.$store.commit('setIsLoading', true)
	  		await axios
	                .get("/api/items/?image_sizes=550x590&user_image_sizes=30x30&page="+this.currentPage+"&page_size="+this.page_size)
	                .then(response => {
						console.log(response.data.results)
						this.assets=response.data.results
						this.count = response.data.count;
	                	this.num_pages = this.count/this.page_size;
						if(response.data.next) {
	                    	this.showNext=true
	                    } else {
	                    	this.showNext=false
	                    }
	                    if(response.data.previous) {
	                    	this.showPrev=true
	                    } else {
	                    	this.showPrev=false
	                    }
	                    this.$store.commit('setIsLoading', false)
	                })
	                .catch(error => {
	                	this.$store.commit('setIsLoading', false)
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	    	
	  	},
   }
}
</script>