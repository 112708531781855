<template>
<div class="inner-banner inner-bg6">
<div class="container">
<div class="inner-title">
<h3> Blog Details</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>Community</li>
<li> Blog Details</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>


<div class="blog-details-area pt-100 pb-70">
<div class="container">
<div class="row">
<div class="col-lg-4">
<div class="side-bar-area pr-20">
<div class="side-bar-profile">
<img :src="api_baseurl+post.user_photo" :alt="post.user_full_name" v-if="post.user_photo" style="width:110px !important;height:110px !important;">
<img src="../assets/dab/images/blog/blog-profile.jpg" :alt="post.user_full_name" v-else>

<h3>{{post.user_full_name}}</h3>
<p></p>
<ul class="social-link">
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
<li>
<a href="https://www.linkedin.com/" target="_blank">
<i class="ri-linkedin-fill"></i>
</a>
</li>
</ul>
</div>

</div>
</div>
<div class="col-lg-8">
<div class="blog-details-content">
<div class="content">
<h1>{{post.title}}</h1>
</div>
<div class="blog-preview-img">
<img :src="api_baseurl+post.featured_image" :alt="post.title" v-if="post.featured_image">
<img src="../assets/dab/images/blog/blog-details-img1.jpg" :alt="post.title" v-else>
</div>
<div class="blog-articel">
<div v-html="post.content"/>
</div>

<div class="article-share-area">
<div class="row align-items-center">
<div class="col-lg-5 col-sm-5">
</div>
<div class="col-lg-7 col-sm-7">
<div class="article-social-icon">
<ul class="social-icon">
<li class="title">Share Post</li>
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
<li>
<a href="https://www.linkedin.com/" target="_blank">
<i class="ri-linkedin-fill"></i>
</a>
</li>
</ul>
</div>
</div>
</div>
</div>

</div>
</div>
</div>
</div>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Post',
    components: {
    },
    data() {
        return {
            post: {},
            api_baseurl:"",
        }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        this.getPost()
    },
    watch: {
        $route(to, from) {
            if (to.name === 'Post') {
                this.getPost()
            }
        }
    },
    methods: {
        async getPost() {
            const postSlug = this.$route.params.post_slug

            this.$store.commit('setIsLoading', true)

            axios
                .get(`/api/posts/${postSlug}/`)
                .then(response => {
                    this.post = response.data
                    document.title = this.post.title 
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>