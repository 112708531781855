<template>
<div class="inner-banner inner-bg3">
<div class="container">
<div class="inner-title">
<h3>Search results</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>Search</li>
<li>Results</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>

<div class="top-sellers-area pb-70" v-if="authors.length">
<div class="container">
<div class="row">
<div class="col-lg-8 col-md-7">
<div class="section-title">
<h2>Authors</h2>
</div>
</div>
</div>
<div class="row justify-content-center pt-45">
<div class="col-lg-12 col-12 col-md-12" v-for="(user,index) in authors">
<div class="top-sellers-item">
<div class="content">
<h3><a :href="'/author-profile/'+user.uuid+'/'">{{user.full_name}}</a></h3>
</div>
</div>
</div>

</div>
</div>
</div>


<div class="top-sellers-area pb-70" v-if="items.length">
<div class="container">
<div class="row">
<div class="col-lg-8 col-md-7">
<div class="section-title">
<h2>Items</h2>
</div>
</div>
</div>
<div class="row justify-content-center pt-45">
<div class="col-lg-12 col-12 col-md-12" v-for="(item,index) in items">
<div class="top-sellers-item">
<div class="content">
<h3><a :href="'/items/'+item.uuid+'/'">{{item.title}}</a></h3>
</div>
</div>
</div>

</div>
</div>
</div>

<div class="top-sellers-area pb-70" v-if="!items.length && !authors.length">
<div class="container">
<div class="row justify-content-center pt-45">
<div class="col-lg-12 col-12 col-md-12">
<div class="top-sellers-item">
<div class="content">
<p>There are no results</p>
</div>
</div>
</div>

</div>
</div>
</div>


<div class="involved-area-two pt-100 pb-70">
<div class="container">
<div class="section-title text-center">
<h2>Get Involved</h2>
</div>
<div class="row pt-45">
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-flight-takeoff-line"></i>
</div>
<h3>Join Our <b>Community</b></h3>
<ul class="social-link">
<li>
<a href="https://www.google.com/" target="_blank">
<i class="ri-google-fill"></i>
</a>
</li>
<li>
<a href="https://www.facebook.com/" target="_blank">
<i class="ri-facebook-fill"></i>
</a>
</li>
<li>
<a href="https://www.instagram.com/" target="_blank">
<i class="ri-instagram-fill"></i>
</a>
</li>
<li>
<a href="https://twitter.com/" target="_blank">
<i class="ri-twitter-fill"></i>
</a>
</li>
<li>
<a href="https://www.linkedin.com/" target="_blank">
<i class="ri-linkedin-fill"></i>
</a>
</li>
</ul>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-checkbox-circle-line"></i>
</div>
<h3>Become A <b>Creator</b></h3>
<a href="/register/" class="default-btn">Register</a>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-heart-2-line"></i>
</div>
<h3>Become A <b>Charity Partner</b></h3>
<a href="/create-collection/" class="default-btn">Express Interest</a>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="involved-card">
<div class="icon">
<i class="ri-discuss-line"></i>
</div>
<h3>Reach Out To <b>Our Team</b></h3>
<a href="/contact-us/" class="default-btn">Contact Now</a>
</div>
</div>
</div>
</div>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Search',
    components: {
    },
    data() {
        return {
          items: [],
          query: '',
          authors:[],
	      rate_amount:1,
	      api_baseurl:"",
	      count:0,
	      currentPage:1,
	      page_size:6,
	      showNext:false,
	      showPrev:false,
	      showPagination:false,
	      num_pages:0,
        }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        document.title = 'Search'

        let uri = window.location.search.substring(1)
        let params = new URLSearchParams(uri)

        if (params.get('query')) {
            this.query = params.get('query')

            this.performSearch()
        }
    },
    methods: {
       loadPrev: function () {
    	 this.currentPage -=1
    	 this.getAssets()
        },
        loadNext: function () {
    	  this.currentPage +=1
    	  this.getAssets()
        },
   	    goto(url){
		  window.location.href = url
	    },
        async performSearch() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/search/?query='+this.query, {'query': this.query})
                .then(response => {
                    this.items = response.data.items
                    console.log(response.data.items)
                    this.authors = response.data.authors
                    console.log(response.data.authors)
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>