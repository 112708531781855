<template>
<div class="inner-banner inner-bg7">
<div class="container">
<div class="inner-title">
<h3>Latest News And Blog</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>Community</li>
<li> Blog</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>


<div class="blog-area-two pt-100 pb-70">
<div class="container">
<div class="section-title">
<h2>From Our Blog</h2>
</div>
<div class="row justify-content-center pt-45">
<div class="col-lg-4 col-md-6" v-for="post in posts">
<div class="blog-card box-shadow">
<div class="blog-img">
<a :href="'/posts/'+post.slug+'/'">
<img :src="api_baseurl+post.featured_image" :alt="post.title" v-if="post.featured_image">
<img src="../assets/dab/images/blog/blog-img1.jpg" :alt="post.title" v-else>
</a>
 <div class="blog-user">
<a :href="'/author-profile/'+post.user_uuid+'/'" class="blog-user-option">
<img :src="api_baseurl+post.user_photo" :alt="post.user_full_name" v-if="post.user_photo">
<img src="../assets/dab/images/blog/blog-user1.jpg" :alt="post.user_full_name" v-else>
<span>Created by @{{post.user_username}}</span>
</a>
</div>
</div>
<div class="content">
<h3><a :href="'/posts/'+post.slug+'/'">{{post.title}}</a></h3>
</div>
</div>
</div>

<div class="col-lg-12 col-md-12 text-center">
<div class="pagination-area">
<a href="blog-1.html" class="prev page-numbers">
<i class="ri-arrow-left-s-line"></i>
</a>
<span class="page-numbers current" aria-current="page">1</span>
<a href="blog-1.html" class="page-numbers">2</a>
<a href="blog-1.html" class="page-numbers">3</a>
<a href="blog-1.html" class="next page-numbers">
<i class="ri-arrow-right-s-line"></i>
</a>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'Blog',
  	data() {
	    return {
	    	posts:[],
	    	api_baseurl:"",
	    }
    },
    mounted() {
    	this.api_baseurl= axios.defaults.baseURL
        document.title = 'Blog'
        this.getPosts()
    },
    methods: {
    	async getPosts() {
	  		await axios
	                .get("/api/posts/")
	                .then(response => {
						console.log(response.data.results)
						this.posts = response.data.results
	                })
	                .catch(error => {
	                    if (error.response) {
	                        for (const property in error.response.data) {
	                            this.errors.push(`${property}: ${error.response.data[property]}`)
	                        }
	                    } else {
	                        this.errors.push('Something went wrong. Please try again')
	                        
	                        console.log(JSON.stringify(error))
	                    }
	    	})
	  	},
    }
}
</script>