<template>
	<div class="inner-banner inner-bg6">
<div class="container">
<div class="inner-title">
<h3>Connect Your Wallet</h3>
<ul>
<li>
<a href="/">Home</a>
</li>
<li>Community</li>
<li> Add Wallet</li>
</ul>
<div class="inner-shape">
<img src="../assets/dab/images/inner-banner/inner-shape1.png" alt="Images">
<img src="../assets/dab/images/inner-banner/inner-shape2.png" alt="Images">
</div>
</div>
</div>
</div>


<div class="wallet-area pt-100 pb-70">
<div class="container">
<div class="section-title">
<h2>Connect Your wallet</h2>
<p>Connect with one of available wallet providers or create a new wallet. <a href="/pages/help-support/">What is a wallet?</a> </p>
</div>
<div class="row pt-45">

<div class="col-lg-4 col-6">
<div class="wallet-card">
<img src="../assets/dab/images/wallet-img/mm-logo.svg" alt="Metamask">
<h3><a href="https://metamask.io/">Metamask</a></h3>
<p>Connect with a crypto wallet & gateway to blockchain apps</p>
<div class="top-btn">Simple</div>
</div>
</div>

<div class="col-lg-12">
<div class="wallet-text">
<p>We do not own your private keys and cannot access your funds without your confirmation.</p>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
export default {
    name: 'AddWallet',
    mounted() {
        document.title = 'Add Wallet'
    },
}
</script>